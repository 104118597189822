import { AmlSettings } from 'types/AmlSettings';
import { YesOrNo } from './YesOrNoRadioBoxGroup';
import { creditCheckScoreList } from './constant';
import { ICreditScoreInput, IRuleSetAttributes } from './type';
import { FEATURE_FLAGS } from 'conf';

type CreditCheckRule = {
  id: number;
  slug: string;
  name: string;
  max_credit_risk_indicator_business: number;
  min_credit_risk_indicator_business: number;
  max_credit_risk_indicator_personal: number;
  min_credit_risk_indicator_personal: number;
  auto_decision_title: {
    business_check: string;
    personal_check: string;
  };
};

export const getCreditScoreInput = (
  creditCheckRule: CreditCheckRule,
  type: 'business' | 'personal'
) => {
  if (!creditCheckRule) {
    return null;
  }

  const {
    max_credit_risk_indicator_business,
    min_credit_risk_indicator_business,
    max_credit_risk_indicator_personal,
    min_credit_risk_indicator_personal,
    auto_decision_title,
  } = creditCheckRule;

  let minScore;
  let maxScore;
  let description;

  let typeLabel = '';
  switch (type) {
    case 'business':
      typeLabel = 'Company';
      break;
    case 'personal':
      typeLabel = 'Personal';
      break;
    default:
      break;
  }

  if (type === 'business') {
    minScore = min_credit_risk_indicator_business;
    maxScore = max_credit_risk_indicator_business;
    description = `${auto_decision_title.business_check}`;
  } else if (type === 'personal') {
    minScore = min_credit_risk_indicator_personal;
    maxScore = max_credit_risk_indicator_personal;
    description = `${auto_decision_title.personal_check}`;
  }

  return {
    minScore,
    maxScore,
    description,
  };
};

export const getScoreInput = (
  id: string | null,
  type: 'personal' | 'business' | 'individual',
  region: 'AU' | 'NZ'
): ICreditScoreInput | undefined => {
  const creditScore =
    id &&
    creditCheckScoreList.find(
      (creditScore) =>
        creditScore.type === type &&
        creditScore.id == id &&
        creditScore.region === region
    );

  let typeLabel = '';
  if (type === 'business') {
    typeLabel = 'Company';
  } else if (type === 'personal') {
    typeLabel = 'Personal';
  }

  return creditScore
    ? {
        minScore: creditScore.minScore,
        maxScore: creditScore.maxScore,
        description: `${creditScore.description} (${typeLabel}, ${creditScore.minScore}~${creditScore.maxScore})`,
      }
    : undefined;
};

export const checkValidationFormComplete = (
  formValues: {
    require_anti_fraud: YesOrNo;
    aml_verification: YesOrNo;
    guarantors: YesOrNo;
    guarantors_count: string | number;
    signatories: YesOrNo;
    signatories_count: string | number;
  },
  isAmlEnabledInSettings: boolean,
  isAntiFraudApplicable: boolean,
  settingsGuarantorCount: number,
  isCreditRuleset: boolean,
  isSoleTraderEntitySelected: boolean
) => {
  // 1CAF is enabled in account settings, but no selection is made in 1CAD ruleset
  const isAntiFraudNotSelected =
    formValues.require_anti_fraud === YesOrNo.NOT_SELECTED &&
    isAntiFraudApplicable;

  // AML is enabled in account settings, but no selection is made in 1CAD ruleset
  const isAmlNotSelected =
    formValues.aml_verification === YesOrNo.NOT_SELECTED &&
    isAmlEnabledInSettings;

  const isGuarantorNotSelected =
    (formValues.guarantors === YesOrNo.NOT_SELECTED &&
      settingsGuarantorCount) ||
    (formValues.guarantors === YesOrNo.YES &&
      settingsGuarantorCount &&
      !formValues.guarantors_count);

  const isSignatoryNotSelected =
    formValues.signatories === YesOrNo.NOT_SELECTED ||
    (!formValues.signatories_count && formValues.signatories === YesOrNo.YES);

  const hasEmptyFields = FEATURE_FLAGS.FEATURE_FLAG_1CAD_CASH_RULE_CHANGES
    ? isAntiFraudNotSelected ||
      isAmlNotSelected ||
      (isCreditRuleset && (isGuarantorNotSelected || isSignatoryNotSelected)) ||
      (isSoleTraderEntitySelected && isSignatoryNotSelected)
    : isAntiFraudNotSelected ||
      isAmlNotSelected ||
      isGuarantorNotSelected ||
      isSignatoryNotSelected;

  return !hasEmptyFields;
};

export const checkAmlEnabledInSettings = (amlSettings: AmlSettings) => {
  const { addonConfigActive, selectedProviders, selectedLevels } =
    amlSettings || {
      addonConfigActive: false,
      selectedProviders: { NZ: null },
      selectedLevels: [],
    };
  const isAmlApplicable =
    addonConfigActive === true &&
    selectedProviders.NZ !== null &&
    selectedLevels.length > 0;
  return isAmlApplicable;
};

export const processFinancialAddons = (
  financialAddons: any,
  ruleset: IRuleSetAttributes
) => {
  const rulesetMinLimit = ruleset.min_credit_value;
  const rulesetMaxLimit = ruleset.max_credit_value;
  const rulesetEntityTypes = ruleset.legal_types;

  const matchingFinancialRule = financialAddons.find((addon) => {
    const isFinancialConfigActive = addon.attributes.active;
    if (!isFinancialConfigActive) {
      return false;
    }
    const financialAttributes =
      addon.attributes?.history_version?.data[0]?.attributes;
    if (financialAttributes) {
      const financialMinLimit = financialAttributes.min_credit_value;
      const financialMaxLimit = financialAttributes.max_credit_value;
      const financialEntityTypes = financialAttributes.legal_types;
      const hasCreditLimitOverlap =
        rulesetMinLimit >= financialMinLimit &&
        rulesetMaxLimit <= financialMaxLimit;
      const hasEntityTypeOverlap = rulesetEntityTypes.some((entityType) =>
        financialEntityTypes.includes(entityType)
      );

      return hasCreditLimitOverlap && hasEntityTypeOverlap;
    }
    return false;
  });

  let hasAssetsLiabilities = false;
  let hasIncomeExpenditure = false;

  if (matchingFinancialRule) {
    const mandatoryFields =
      matchingFinancialRule.attributes?.history_version?.data[0]?.attributes
        ?.config?.mandatory_fields;
    if (mandatoryFields && Array.isArray(mandatoryFields)) {
      hasAssetsLiabilities = mandatoryFields.includes('assets_liabilities');
      hasIncomeExpenditure = mandatoryFields.includes('income_expenses');
    }
  }

  const isFinancialsEditable = Boolean(
    matchingFinancialRule && (hasAssetsLiabilities || hasIncomeExpenditure)
  );

  return {
    isFinancialsEditable,
    hasAssetsLiabilities,
    hasIncomeExpenditure,
  };
};

/**
 * Processes anti-fraud settings to determine if there is a matching rule
 * for the specified 1CAD ruleset, based on specified criteria
 * such as application type, legal entity types, and credit limits.
 *
 * @param {Array} antiFraudSettings - An array of anti-fraud rules to be processed.
 * @param {IRuleSetAttributes} ruleset - The rule set attributes used for matching.
 * @returns {boolean} - Returns true if a matching anti-fraud rule is found; otherwise, false.
 */
export const processAntiFraudSettings = (
  antiFraudSettings: any,
  ruleset: IRuleSetAttributes
) => {
  // Extract relevant information from the ruleset
  const rulesetApplicationType = ruleset.application_types[0];
  const rulesetEntityTypes = ruleset.legal_types;
  const rulesetMinLimit = ruleset.min_credit_value;
  const rulesetMaxLimit = ruleset.max_credit_value;

  // Find a matching anti-fraud rule based on specified criteria
  const matchingAntiFraudRule = antiFraudSettings.find((antiFraudRule) => {
    // Extract attributes from the latest version of the anti-fraud rule
    const latestVersionAttributes =
      antiFraudRule.attributes.history_version.data[0].attributes;
    const antiFraudAccountTypes = latestVersionAttributes.config
      .account_types || ['credit', 'cash'];
    const {
      legal_types: antiFraudEntityTypes,
      min_credit_value: antiFraudMinCreditValue,
      max_credit_value: antiFraudMaxCreditValue,
    } = latestVersionAttributes;

    // Check for application type overlap
    const hasApplicationTypeOverlap = rulesetApplicationType
      ? antiFraudAccountTypes.includes(rulesetApplicationType)
      : false;

    // Check for entity type overlap
    const hasEntityTypeOverlap = rulesetEntityTypes.some((rulesetEntityType) =>
      antiFraudEntityTypes.includes(rulesetEntityType)
    );

    // Check for credit limit overlap
    let hasCreditLimitOverlap = true;
    if (antiFraudMinCreditValue !== null && antiFraudMaxCreditValue !== null) {
      hasCreditLimitOverlap =
        rulesetMinLimit >= antiFraudMinCreditValue &&
        rulesetMaxLimit <= antiFraudMaxCreditValue;
    }

    // Return true if all criteria overlap, indicating a matching rule
    return (
      hasApplicationTypeOverlap && hasEntityTypeOverlap && hasCreditLimitOverlap
    );
  });

  // Return true if a matching rule is found; otherwise, false
  return Boolean(matchingAntiFraudRule);
};

/**
 * Process Direct Debit Addons to determine if there is a matching rule for the given 1CAD ruleset.
 *
 * @param {Array} directDebitAddons - An array of direct debit addons configured by the supplier.
 * @param {IRuleSetAttributes} ruleset - The rule set attributes used for matching.
 * @returns {boolean} - Returns true if a matching direct debit rule is found; otherwise, returns false.
 */
export const processDirectDebitAddons = (
  directDebitAddons: any,
  ruleset: IRuleSetAttributes
) => {
  const rulesetApplicationType = ruleset.application_types[0];
  const rulesetEntityTypes = ruleset.legal_types || [];
  const rulesetMinLimit = ruleset.min_credit_value;
  const rulesetMaxLimit = ruleset.max_credit_value;

  const matchingDirectDebitRule = directDebitAddons.find((directDebitAddon) => {
    const isActive = directDebitAddon.attributes.active;
    if (!isActive) {
      return;
    }
    const latestVersionAttributes =
      directDebitAddon.attributes.history_version.data[0].attributes;

    const directDebitApplicationTypes =
      latestVersionAttributes.application_types;
    const directDebitEntityTypes = latestVersionAttributes.legal_types;
    const directDebitMinLimit = latestVersionAttributes.min_credit_value;
    const directDebitMaxLimit = latestVersionAttributes.max_credit_value;

    const hasApplicationTypeOverlap = rulesetApplicationType
      ? directDebitApplicationTypes.includes(rulesetApplicationType)
      : false;
    const hasEntityTypeOverlap = rulesetEntityTypes.some((rulesetEntityType) =>
      directDebitEntityTypes.includes(rulesetEntityType)
    );
    let hasCreditLimitOverlap = true;
    if (rulesetApplicationType && rulesetApplicationType === 'credit') {
      hasCreditLimitOverlap =
        rulesetMinLimit >= directDebitMinLimit &&
        rulesetMaxLimit <= directDebitMaxLimit;
    }

    return (
      hasApplicationTypeOverlap && hasEntityTypeOverlap && hasCreditLimitOverlap
    );
  });

  return Boolean(matchingDirectDebitRule);
};

export const getOtherTabData = ({
  entityTypes,
  applicationType,
  regions,
}: {
  entityTypes: string[];
  applicationType: string;
  regions: string[];
}) => {
  if (!FEATURE_FLAGS.FEATURE_FLAG_1CAD_CASH_RULE_CHANGES) {
    return {};
  }

  let returnObject: {
    guarantors?: boolean;
    guarantors_count?: null;
    signatories?: boolean;
    signatories_count?: null;

    credit_checks_required?: boolean;
    au_individual_credit_indicator?: null;
    au_personal_credit_indicator?: null;
    nz_personal_credit_indicator?: null;
    au_business_credit_indicator?: null;
    nz_business_credit_indicator?: null;
    defaults?: boolean;
    judgements?: boolean;
  } = {};

  const isCompanyEntitySelected = entityTypes.includes('company');
  const isPersonalEntitySelected = entityTypes.includes('personal');
  const isSoleTraderEntitySelected = entityTypes.includes('sole_trader');

  const isNZSelected = regions.includes('NZ');
  const isAUSelected = regions.includes('AU');

  if (applicationType === 'cash') {
    // Guarantors and signatories not applicable for cash rulesets
    returnObject.guarantors = false;
    returnObject.guarantors_count = null;
    if (!isSoleTraderEntitySelected) {
      // Well, signatories are applicable for Sole Trader applications.
      // So this part applies only if Sole Trader is not selected.
      returnObject.signatories = false;
      returnObject.signatories_count = null;
    }

    if (isNZSelected && !isAUSelected) {
      if (!isPersonalEntitySelected && !isSoleTraderEntitySelected) {
        returnObject.nz_personal_credit_indicator = null;
      }
      returnObject.au_business_credit_indicator = null;
      returnObject.au_individual_credit_indicator = null;
      returnObject.au_personal_credit_indicator = null;
    }

    if (!isNZSelected && isAUSelected) {
      if (!isPersonalEntitySelected) {
        returnObject.au_individual_credit_indicator = null;
      }
      if (!isSoleTraderEntitySelected) {
        returnObject.au_personal_credit_indicator = null;
      }
      returnObject.nz_business_credit_indicator = null;
      returnObject.nz_personal_credit_indicator = null;
    }

    if (isNZSelected && isAUSelected) {
      if (!isPersonalEntitySelected && !isSoleTraderEntitySelected) {
        returnObject.nz_personal_credit_indicator = null;
        returnObject.au_personal_credit_indicator = null;
        returnObject.au_individual_credit_indicator = null;
      }
      if (isPersonalEntitySelected && !isSoleTraderEntitySelected) {
        returnObject.au_personal_credit_indicator = null;
      }
      if (!isPersonalEntitySelected && isSoleTraderEntitySelected) {
        returnObject.au_individual_credit_indicator = null;
      }
    }
  }

  if (!isCompanyEntitySelected) {
    // Company credit checks not applicable if 'Company' is not selected as
    // an entity type, regardless of application type
    returnObject.au_business_credit_indicator = null;
    returnObject.nz_business_credit_indicator = null;

    // Guarantors not applicable if 'Company' is not selected as an entity type,
    // regardless of application type
    returnObject.guarantors = false;
    returnObject.guarantors_count = null;
  }

  if (FEATURE_FLAGS.FEATURE_FLAG_EQUIFAX_CONSUMER) {
    if (
      applicationType === 'cash' &&
      !isCompanyEntitySelected &&
      !isPersonalEntitySelected &&
      !isSoleTraderEntitySelected
    ) {
      // Credit checks have become not applicable
      returnObject.credit_checks_required = false;
      returnObject.defaults = false;
      returnObject.judgements = false;
    }
  } else if (
    applicationType === 'cash' &&
    !isCompanyEntitySelected &&
    !isPersonalEntitySelected
  ) {
    // Credit checks have become not applicable
    returnObject.credit_checks_required = false;
    returnObject.defaults = false;
    returnObject.judgements = false;
  }

  return returnObject;
};

export const isEntityTypeSelected = (
  legalTypes: string[],
  entityType: string
) => {
  if (!legalTypes || !entityType) {
    return false;
  }
  return legalTypes.includes(entityType);
};
