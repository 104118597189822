import { Typography } from '@material-ui/core';
import { watchtowerAlertTheme } from 'modules/shared/helpers/colorPalettes';
import { Card, CardContent } from 'mui-latest';
import styled from 'styled-components';
import { COLORS } from 'variables/theme';

export const TableLabel = styled(Typography)`
  margin-top: 1em;
  padding: 10px 0px;
`;

const statusColorMap = {
  irregular: watchtowerAlertTheme.status_irregular,
  watching: watchtowerAlertTheme.status_watching,
};

export const StatusWrapper = styled.div`
  background-color: ${(props: { variant: string }) =>
    statusColorMap[props.variant]};

  padding: 1em 2em;
  margin: 1em 0;
  border-radius: 7px;
`;

export const ActionTextWrapper = styled.span`
  color: ${(props: { status: string }) =>
    props.status === 'blocked'
      ? watchtowerAlertTheme.blocked_text
      : 'rgba(0, 0, 0, 0.87)'};
`;

export const ActionWrapper = styled.span<{ $isDisabled?: boolean }>`
  a {
    color: ${({ $isDisabled }) =>
      $isDisabled ? COLORS.darkGrey : 'var(--main-color-active) !important'};
    cursor: ${({ $isDisabled }) => ($isDisabled ? 'default' : 'pointer')};
    text-decoration: underline;
    margin-right: 2em;
  }
  a:hover {
    text-decoration: underline;
  }
`;

export const LinkedApplicationWrapper = styled.span`
  a {
    color: var(--main-color-active) !important;
    text-decoration: underline;
    margin-right: 1em;
  }
  a:hover {
    text-decoration: underline;
  }
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
`;

const rowStatusColorMap = {
  internal_open: watchtowerAlertTheme.internal_alert,
  external_open: watchtowerAlertTheme.external_alert,
  internal: watchtowerAlertTheme.internal_alert,
  external: watchtowerAlertTheme.external_alert,
};

const labelBgMap = {
  fraudulent: 'red',
  undesirable: '#ffb601ff',
  new: '#dceefe',
};

const labelTextMap = {
  new: '#3e82fe',
};

// @value blue: #00afef;
// @value blue-light: #7fcef3;

type TableRowProps = {
  rowStatus?: string;
  alertType?: string;
  firstGroupMember?: boolean;
  lastGroupMember?: boolean;
};

export const TableRowWrapper = styled.tr`
  background-color: ${(props: TableRowProps) =>
    props.rowStatus ? rowStatusColorMap[props.rowStatus] : 'initial'};

  ${(props: TableRowProps) => {
    if (props.alertType === 'internal') {
      return `
        border: none !important;
        td {
          border: 2px solid #f6f6f6;
        }

        td:first-child {
          border-top: ${props.firstGroupMember ? '2px solid #f6f6f6' : 'none'};
          border-bottom: ${
            props.lastGroupMember ? '2px solid #f6f6f6' : 'none'
          };
        }
      `;
    }
    return '';
  }}
`;

// New watchtower VCF styles
export const WatchtowerAlertSummaryWrapper = styled.div`
  margin: 1em 0;
  display: flex;
  flex-direction: row;
  gap: 1em;
`;

export const WatchtowerActionAll = styled.a`
  text-decoration: underline;
  margin-top: 1em;
`;

export const WatchtowerActionAllDisabled = styled.span`
  margin-top: 1em;
  text-decoration: underline;
  color: var(--main-color) !important;
  opacity: 0.3;
  cursor: default;
`;

export const WatchtowerAlertsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding-bottim: 2em;
`;

export const WatchtowerAlertActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3em;
  margin-bottom: 1em;
`;

export const WatchtowerFraudulentAccountWrapper = styled.div<{
  $disabled: boolean;
}>`
  display: flex;
  align-items: center;
  color: #f44335 !important;
  margin-left: -20px;
  cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.$disabled ? '0.3' : 'initial')};
  span {
    margin-left: 3px;
    text-decoration: underline;
  }
`;

interface WatchtowerAlertHeaderRootProps {
  alert_type?: string;
  disabled?: boolean;
}

export const WatchtowerAlertHeaderRoot = styled(Card)`
  width: 100%;
  min-height: 50px;
  padding: 10px 16px;
  background-color: ${(props: WatchtowerAlertHeaderRootProps) =>
    props.alert_type
      ? rowStatusColorMap[props.alert_type]
      : 'initial'} !important;
  :hover {
    cursor: ${(props: WatchtowerAlertHeaderRootProps) =>
      props.disabled ? 'default' : 'pointer'} !important;
  }
`;

export const WatchtowerAlertHeader = styled(CardContent)`
  padding: 0 !important;
  display: grid;
  grid-template-columns: 10px 1fr 100px;
  align-items: center;
  span:first-child {
    align-self: start;
  }
`;

export const WatchtowerAlertTag = styled.span`
  color: ${(props: { label?: string }) =>
    props.label && labelTextMap[props.label]
      ? labelTextMap[props.label]
      : 'white'} !important;
  padding: 2px 10px;
  background-color: ${(props: { label?: string }) =>
    props.label ? labelBgMap[props.label] : 'initial'} !important;
  font-size: 9px;
  font-weight: bold !important;
  width: fit-content;
  text-transform: uppercase;
  ${(props: { label?: string }) =>
    props.label && props.label === 'new'
      ? 'margin-left: 5px; vertical-align: middle;'
      : ''}
  ${(props: { label?: string }) =>
    props.label && ['fraudulent', 'undesirable'].includes(props.label)
      ? 'line-height: 13px; margin-left: 5px;'
      : ''}
`;

export const WatchtowerAlertBucketRoot = styled(Card)`
  background-color: #f6f6f6 !important;
`;

export const WatchtowerAlertInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  gap: 0;
  span:last-child {
    color: #5e5e5e;
    margin-top: -3px;
  }
`;

export const WatchtowerAlertRowWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props: { linked?: boolean }) =>
    props.linked ? '140px repeat(2, 2fr) 65px repeat(2, 80px);' : '200px 1fr;'}
  grid-column-gap: 5px;
  margin-bottom: 8px;

  div {
    word-wrap: break-word;
    min-width: 0;
  }
`;

export const WatchtowerTableHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props: { linked?: boolean }) =>
    props.linked ? '140px repeat(2, 2fr) 65px repeat(2, 80px);' : '200px 1fr;'}
  grid-column-gap: 5px;
  color: #5e5e5e;
  margin-bottom: 8px;
`;

export const WatchtowerAlertTableWrapper = styled.div`
  padding: 0px 20px;
`;
