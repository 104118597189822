import React, { Fragment } from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { checkHasSectionData, getPpsrFinanceStatementDetail } from './utils';
import styles from './summary-report-styles.css';
import { SectionContentContainer } from '../styles';
import { IPPSRFinanceStatementDetails } from './types';
import { formatDate } from 'utils/dateFormatter';

const RenderSecuredParty = ({ securedParty }) => {
  return typeof securedParty === 'string' ? (
    <p>{securedParty}</p>
  ) : (
    <Fragment>
      {securedParty.Surname && securedParty.FirstNames && (
        <p>{`${securedParty.Surname}, ${securedParty.FirstNames}`}</p>
      )}
      {securedParty.OrganisationName && <p>{securedParty.OrganisationName}</p>}
    </Fragment>
  );
};

const PpsrFinanceStatementDetailContents = ({
  ppsrFinanceStatementDetails,
}: {
  ppsrFinanceStatementDetails: IPPSRFinanceStatementDetails;
}) => {
  const { FinanceStatementDetails = [] } = ppsrFinanceStatementDetails;

  return (
    <SectionContentContainer>
      {!!FinanceStatementDetails &&
        FinanceStatementDetails.length > 0 &&
        FinanceStatementDetails.map((FinanceStatementDetail, index) => {
          const {
            FinancialStatementNumber,
            SecuredParties,
            RegisteredDate,
            ExpiryDate,
            Version,
            LastUpdated,
            CollateralDetails = [],
          } = FinanceStatementDetail;
          return (
            <Fragment key={index}>
              <table className={styles.table}>
                <tbody>
                  <tr className={styles.row}>
                    <th className={styles.th + ' ' + styles.col_3}>
                      FS Number
                    </th>
                    <th className={styles.th + ' ' + styles.col_2}>
                      Secured Party
                    </th>
                    <th className={styles.th + ' ' + styles.col_2}>
                      Registration Date
                    </th>
                    <th className={styles.th + ' ' + styles.col_2}>
                      Expiry Date
                    </th>
                    <th className={styles.th + ' ' + styles.col_1}>
                      Version Number
                    </th>
                    <th className={styles.th + ' ' + styles.col_2}>
                      Date and Time Last Amended
                    </th>
                  </tr>
                  <tr className={styles.row}>
                    <td className={styles.td + ' ' + styles.col_3}>
                      {FinancialStatementNumber || '-'}
                    </td>
                    <td className={styles.td + ' ' + styles.col_2}>
                      {!!SecuredParties && SecuredParties.length > 0 ? (
                        Array.isArray(SecuredParties) ? (
                          SecuredParties.map((securedParty) => (
                            <RenderSecuredParty securedParty={securedParty} />
                          ))
                        ) : (
                          <RenderSecuredParty securedParty={SecuredParties} />
                        )
                      ) : (
                        '-'
                      )}
                    </td>
                    <td className={styles.td + ' ' + styles.col_2}>
                      {RegisteredDate
                        ? formatDate(RegisteredDate, 'DD/MM/YYYY')
                        : '-'}
                    </td>
                    <td className={styles.td + ' ' + styles.col_2}>
                      {ExpiryDate ? formatDate(ExpiryDate, 'DD/MM/YYYY') : '-'}
                    </td>
                    <td className={styles.td + ' ' + styles.col_1}>
                      {typeof Version === 'number' ? Version : '-'}
                    </td>
                    <td className={styles.td + ' ' + styles.col_2}>
                      {LastUpdated
                        ? formatDate(LastUpdated, 'DD/MM/YYYY')
                        : '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
              {!!CollateralDetails &&
                CollateralDetails.length > 0 &&
                CollateralDetails.map((collateralDetail, index) => {
                  const {
                    Type,
                    Type_Description,
                    Co_Description,
                    Co_Items = [],
                  } = collateralDetail;

                  return (
                    <table className={styles.table} key={index}>
                      <tbody>
                        <tr className={styles.row}>
                          <th className={styles.th + ' ' + styles.col_3}>
                            Collateral Type
                          </th>
                          <th className={styles.th + ' ' + styles.col_9}>
                            Description
                          </th>
                        </tr>
                        <tr className={styles.row}>
                          <td className={styles.td + ' ' + styles.col_3}>
                            {Type || Type_Description
                              ? `${Type}${
                                  Type_Description
                                    ? `${Type ? ' - ' : ''}` + Type_Description
                                    : ''
                                }`
                              : '-'}
                          </td>
                          <td className={styles.td + ' ' + styles.col_9}>
                            {!!Co_Description && (
                              <p>
                                Description: <span>{Co_Description}</span>
                              </p>
                            )}
                            {!!Co_Items &&
                              Co_Items.length > 0 &&
                              Co_Items.map((coItem, index) => {
                                const {
                                  Coi_Description,
                                  Make,
                                  Model,
                                  Year,
                                  Colour,
                                  Vin,
                                  Chassis,
                                  Registration_Number,
                                  Registration_Mark,
                                  Aircraft_Class,
                                  Identifying_Numbers,
                                  Manufacturer_Serial,
                                } = coItem;
                                return (
                                  <Fragment key={index}>
                                    {index !== 0 && <hr />}
                                    {!!Coi_Description && (
                                      <p>{Coi_Description}</p>
                                    )}
                                    {!!Make && (
                                      <p>
                                        Make: <span>{Make}</span>
                                      </p>
                                    )}
                                    {!!Year && (
                                      <p>
                                        Year: <span>{Year}</span>
                                      </p>
                                    )}
                                    {!!Model && (
                                      <p>
                                        Model: <span>{Model}</span>
                                      </p>
                                    )}
                                    {!!Colour && (
                                      <p>
                                        Colour: <span>{Colour}</span>
                                      </p>
                                    )}
                                    {!!Vin && (
                                      <p>
                                        Vin: <span>{Vin}</span>
                                      </p>
                                    )}
                                    {!!Chassis && (
                                      <p>
                                        Chassis: <span>{Chassis}</span>
                                      </p>
                                    )}
                                    {!!Registration_Number && (
                                      <p>
                                        Registration Number:{' '}
                                        <span>{Registration_Number}</span>
                                      </p>
                                    )}
                                    {!!Registration_Mark && (
                                      <p>
                                        Registration Mark:{' '}
                                        <span>{Registration_Mark}</span>
                                      </p>
                                    )}
                                    {!!Aircraft_Class && (
                                      <p>
                                        Aircraft Class:{' '}
                                        <span>{Aircraft_Class}</span>
                                      </p>
                                    )}
                                    {!!Identifying_Numbers && (
                                      <p>
                                        Identifying Numbers:{' '}
                                        <span>{Identifying_Numbers}</span>
                                      </p>
                                    )}
                                    {!!Manufacturer_Serial && (
                                      <p>
                                        Manufacturer Serial:{' '}
                                        <span>{Manufacturer_Serial}</span>
                                      </p>
                                    )}
                                  </Fragment>
                                );
                              })}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  );
                })}
            </Fragment>
          );
        })}
    </SectionContentContainer>
  );
};

const PpsrFinanceStatementDetails = ({ isAllExpanded, data }) => {
  const ppsrFinanceStatementDetails = getPpsrFinanceStatementDetail(data);
  const hasSectionData = checkHasSectionData(ppsrFinanceStatementDetails);

  return (
    <ReviewContainer
      subHeading="PPSR Finance Statement Detail"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <PpsrFinanceStatementDetailContents
          ppsrFinanceStatementDetails={ppsrFinanceStatementDetails}
        />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default PpsrFinanceStatementDetails;
