/* eslint-disable camelcase */
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form-latest';
import * as yup from 'yup-latest';
import get from 'lodash.get';

import RuleSetSection from '../RuleSetSection';
import Button from 'modules/shared/components/inputs/Button';
import { loadAutoDecisions } from 'modules/addons/actions';
import { updateRuleSet } from '../../../actions';
import { loadCurrentEntity } from 'modules/profile/actions.js';
import {
  ICreditChecks,
  ICreditScoreInput,
  IRuleSetAttributes,
  ISelectedCreditCheck,
} from '../type';
import FormFieldSection from '../FormFieldSection';
import YesOrNoRadioBoxGroup, {
  YesOrNo,
  getYesOrNo,
} from '../YesOrNoRadioBoxGroup';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import { Link, makeStyles } from 'mui-latest';
import FormCheckbox from 'modules/shared/components/v2/FormCheckbox';
import {
  getCreditScoreInput,
  getScoreInput,
  isEntityTypeSelected,
} from '../utils';
import { yupResolver } from 'utils/yupResolver/yupResolver';

import './CreditCheckForm.scss';
import { FEATURE_FLAGS } from 'conf';

const applyCashRuleChanges = FEATURE_FLAGS.FEATURE_FLAG_1CAD_CASH_RULE_CHANGES;
const applyCreditCheckFix = FEATURE_FLAGS.FEATURE_FLAG_1CAD_CREDIT_CHECK_FIX;
const applyMandatory1CadJudgementDefault =
  FEATURE_FLAGS.FEATURE_FLAG_1CAD_JUDGEMENT_DEFAULT_MANDATORY;

interface IFormFields {
  nzBusinessCreditIndicator: number;
  nzPersonalCreditIndicator: number;
  auBusinessCreditIndicator: number;
  auPersonalCreditIndicator: number;
  creditChecksRequired: YesOrNo;
  judgements: boolean;
  defaults: boolean;
}
interface IProps {
  attributes: IRuleSetAttributes;
  isReadyToSubmitForm: () => boolean;
  isUpdating: boolean;
  actions: {
    loadCurrentEntity: () => void;
    loadAutoDecisions: () => void;
    updateRuleSet: (id: string, ruleset: ICreditChecks, onSuccess) => void;
  };
  rulesetIndex: number;
  nameModified: boolean;
  entityCreditCheckRules: any;
  selectedCreditChecks: ISelectedCreditCheck;
  creditCheckApplicationTypes: string[];
  navigateToValidationsTab: () => void;
  submitButtonLabel: string;
  onSubmitTabData: (data: ICreditChecks) => void;
  disabled?: boolean;
  hideSaveButton?: boolean;
  rulesetName: IRuleSetAttributes['name'];
  setDirty: (flag: boolean) => void;
}

const useStyles = makeStyles({
  link: {
    color: '#00a5e1',
  },
  checkboxControlRoot: {
    height: '20px',
    marginTop: '-15px',
  },
});

const CreditCheckForm = ({
  attributes,
  isReadyToSubmitForm,
  isUpdating,
  nameModified,
  actions,
  entityCreditCheckRules,
  selectedCreditChecks,
  creditCheckApplicationTypes,
  navigateToValidationsTab,
  submitButtonLabel,
  onSubmitTabData,
  disabled = false,
  hideSaveButton = false,
  rulesetName,
  setDirty,
}: IProps): JSX.Element => {
  const isTabPaused = attributes.credit_checks_status === 'paused';

  const {
    au_personal_rule_selection_id,
    au_rule_selection_id,
    nz_personal_rule_selection_id,
    nz_rule_selection_id,
  } = selectedCreditChecks;

  const nzBusinessScoreInput = applyCreditCheckFix
    ? getCreditScoreInput(entityCreditCheckRules.nz_rule_selection, 'business')
    : getScoreInput(nz_rule_selection_id, 'business', 'NZ');

  const nzPersonalScoreInput = applyCreditCheckFix
    ? getCreditScoreInput(
        entityCreditCheckRules.nz_personal_rule_selection,
        'personal'
      )
    : getScoreInput(nz_personal_rule_selection_id, 'personal', 'NZ');

  const auBusinessScoreInput = applyCreditCheckFix
    ? getCreditScoreInput(entityCreditCheckRules.au_rule_selection, 'business')
    : getScoreInput(au_rule_selection_id, 'business', 'AU');

  const auPersonalScoreInput = applyCreditCheckFix
    ? getCreditScoreInput(
        entityCreditCheckRules.au_personal_rule_selection,
        'personal'
      )
    : getScoreInput(au_personal_rule_selection_id, 'personal', 'AU');

  useEffect(() => {
    actions.loadCurrentEntity();
  }, []);

  const rulesetRegions = attributes.regions || [];
  const isAUScoreRequired = rulesetRegions.includes('AU');
  const isNZScoreRequired = rulesetRegions.includes('NZ');

  const { legal_types } = attributes;

  const isCompanyEntitySelected = isEntityTypeSelected(legal_types, 'company');
  const isTrustEntitySelected = isEntityTypeSelected(legal_types, 'trust');
  const isPartnershipEntitySelected = isEntityTypeSelected(
    legal_types,
    'partnership'
  );
  const isAuBusinessesEntitySelected =
    isCompanyEntitySelected ||
    isTrustEntitySelected ||
    isPartnershipEntitySelected;

  const isPersonalEntitySelected = isEntityTypeSelected(
    legal_types,
    'personal'
  );
  const isSoleTraderEntitySelected = isEntityTypeSelected(
    legal_types,
    'sole_trader'
  );

  const isCreditRuleset = attributes.application_types.includes('credit');

  const isPersonalCreditCheckEnabled = applyCashRuleChanges
    ? isCreditRuleset || isPersonalEntitySelected || isSoleTraderEntitySelected
    : true;

  const isAuBusinessInputApplicable = Boolean(
    isAUScoreRequired && auBusinessScoreInput && isAuBusinessesEntitySelected
  );
  const isAuPersonalInputApplicable = Boolean(
    isAUScoreRequired && auPersonalScoreInput && isPersonalCreditCheckEnabled
  );
  const isNzBusinessInputApplicable = Boolean(
    isNZScoreRequired && nzBusinessScoreInput && isCompanyEntitySelected
  );
  const isNzPersonalInputApplicable = Boolean(
    isNZScoreRequired && nzPersonalScoreInput && isPersonalCreditCheckEnabled
  );

  // Check if credit check is enabled in business profile settings
  // for the ruleset application type
  const isCreditCheckEnabledInSettingsForTheApplicationType =
    attributes.application_types.some((applicationType) =>
      creditCheckApplicationTypes.includes(applicationType)
    );

  const isCreditCheckApplicable = applyCashRuleChanges
    ? Boolean(
        isCreditCheckEnabledInSettingsForTheApplicationType &&
          (isAuBusinessInputApplicable ||
            isAuPersonalInputApplicable ||
            isNzBusinessInputApplicable ||
            isNzPersonalInputApplicable)
      )
    : Boolean(
        isCreditCheckEnabledInSettingsForTheApplicationType &&
          (auBusinessScoreInput ||
            auPersonalScoreInput ||
            nzBusinessScoreInput ||
            nzPersonalScoreInput)
      );

  const classes = useStyles();

  const [selectedCreditCheck, setSelectedCreditCheck] = useState<YesOrNo>(
    isTabPaused && !isCreditCheckApplicable
      ? YesOrNo.NO
      : getYesOrNo(attributes.credit_checks_required)
  );

  const [isAuBusinessChecked, setAuBusinessChecked] = useState(
    attributes.au_business_credit_indicator !== null &&
      attributes.au_business_credit_indicator !== undefined
  );
  const [isAuPersonalChecked, setAuPersonalChecked] = useState(
    attributes.au_personal_credit_indicator !== null &&
      attributes.au_personal_credit_indicator !== undefined
  );

  const [isNzBusinessChecked, setNzBusinessChecked] = useState(
    attributes.nz_business_credit_indicator !== null &&
      attributes.nz_business_credit_indicator !== undefined
  );
  const [isNzPersonalChecked, setNzPersonalChecked] = useState(
    attributes.nz_personal_credit_indicator !== null &&
      attributes.nz_personal_credit_indicator !== undefined
  );

  const defaultValues = {
    creditChecksRequired: selectedCreditCheck,
    auBusinessCreditIndicator: attributes.au_business_credit_indicator,
    auPersonalCreditIndicator: attributes.au_personal_credit_indicator,
    nzBusinessCreditIndicator: attributes.nz_business_credit_indicator,
    nzPersonalCreditIndicator: attributes.nz_personal_credit_indicator,
    judgements: applyMandatory1CadJudgementDefault
      ? true
      : attributes.judgements,
    defaults: applyMandatory1CadJudgementDefault ? true : attributes.defaults,
  };

  const getCreditIndicatorNumberSchema = (
    creditScoreInput: ICreditScoreInput | undefined | null
  ) => {
    const minScore = creditScoreInput ? creditScoreInput.minScore : 0;
    const maxScore = creditScoreInput ? creditScoreInput.maxScore : 0;
    const errorMessage = `Please enter a number in between ${minScore}~${maxScore} `;
    return yup
      .number()
      .typeError(errorMessage)
      .min(minScore, errorMessage)
      .max(maxScore, errorMessage);
  };

  const yupFormSchema =
    selectedCreditCheck === YesOrNo.YES
      ? yup.object().shape({
          ...(isNzBusinessChecked && {
            nzBusinessCreditIndicator:
              getCreditIndicatorNumberSchema(nzBusinessScoreInput),
          }),
          ...(isNzPersonalChecked && {
            nzPersonalCreditIndicator:
              getCreditIndicatorNumberSchema(nzPersonalScoreInput),
          }),
          ...(isAuBusinessChecked && {
            auBusinessCreditIndicator:
              getCreditIndicatorNumberSchema(auBusinessScoreInput),
          }),
          ...(isAuPersonalChecked && {
            auPersonalCreditIndicator:
              getCreditIndicatorNumberSchema(auPersonalScoreInput),
          }),
        })
      : isCreditCheckApplicable
      ? yup.object().shape({
          creditChecksRequired: yup
            .string()
            .required('Please select an option'),
        })
      : null;

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isDirty },
    watch,
  } = useForm<IFormFields>({
    mode: 'onChange',
    defaultValues,
    resolver: yupFormSchema ? yupResolver(yupFormSchema) : null,
  });

  useEffect(() => {
    setDirty(isDirty);
  }, [isDirty]);

  useEffect(() => {
    reset(defaultValues);
  }, [attributes]);

  const currentValues = watch();

  const onSubmit = (values): void => {
    const isReady = isReadyToSubmitForm();

    if (isReady) {
      const isCreditCheckSelected = selectedCreditCheck === YesOrNo.YES;
      const rulesetToSubmit: ICreditChecks = {
        name: rulesetName,
        credit_checks_required: isCreditCheckSelected,
        defaults: values.defaults,
        judgements: values.judgements,
        nz_business_credit_indicator:
          isCreditCheckSelected && isNzBusinessChecked
            ? values.nzBusinessCreditIndicator
            : null,
        nz_personal_credit_indicator:
          isCreditCheckSelected && isNzPersonalChecked
            ? values.nzPersonalCreditIndicator
            : null,
        au_business_credit_indicator:
          isCreditCheckSelected && isAuBusinessChecked
            ? values.auBusinessCreditIndicator
            : null,
        au_personal_credit_indicator:
          isCreditCheckSelected && isAuPersonalChecked
            ? values.auPersonalCreditIndicator
            : null,
        credit_checks_status: 'complete',
      };
      onSubmitTabData(rulesetToSubmit);
    }
  };

  const creditChecksRadioGroupChangeHandler = (value) => {
    setSelectedCreditCheck(value);
  };

  const isNoCreditCheckSelected =
    !isAuBusinessChecked &&
    !isAuPersonalChecked &&
    !isNzBusinessChecked &&
    !isNzPersonalChecked;

  // Check if any of the credit check field that is enabled is empty
  const isAnyCreditCheckMissing =
    (isAuBusinessChecked && !currentValues.auBusinessCreditIndicator) ||
    (isAuPersonalChecked && !currentValues.auPersonalCreditIndicator) ||
    (isNzBusinessChecked && !currentValues.nzBusinessCreditIndicator) ||
    (isNzPersonalChecked && !currentValues.nzPersonalCreditIndicator);

  // Check if user has told that they require credit check,
  // but has not turned a credit check or
  // has turned on a credit check, but not filled in the corresponding value.
  const isCreditCheckMissing =
    currentValues.creditChecksRequired === YesOrNo.YES &&
    (isNoCreditCheckSelected || isAnyCreditCheckMissing);

  let isSaveButtonDisabled =
    (!isDirty && !nameModified) || isCreditCheckMissing || disabled;

  if (isTabPaused && !isCreditCheckApplicable) {
    isSaveButtonDisabled = false;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RuleSetSection title="Credit checks">
        <FormFieldSection
          hasPausedMessage={isTabPaused}
          id="creditChecksRequired"
          label="Do you require credit checks?"
        >
          <YesOrNoRadioBoxGroup
            disabled={!isCreditCheckApplicable || disabled}
            name="creditChecksRequired"
            control={control}
            value={selectedCreditCheck}
            onChange={creditChecksRadioGroupChangeHandler}
            error={get(errors, 'creditChecksRequired.message', '')}
          />
        </FormFieldSection>
      </RuleSetSection>
      {isCreditCheckApplicable && selectedCreditCheck === YesOrNo.YES ? (
        <div className={`creditCheck__content`}>
          <RuleSetSection title="Credit scores">
            <>
              <FormFieldSection
                id="creditScores"
                label={
                  <div>
                    {`Set the minimum credit score to qualify for auto decisioning. If
                    personal credit checks are required, turn on `}
                    <Link
                      className={classes.link}
                      onClick={navigateToValidationsTab}
                    >
                      Guarantors and Signatory/ies
                    </Link>
                    {` here`}.
                  </div>
                }
              />
              <div className="columns is-12">
                {nzBusinessScoreInput && (
                  <div className="column is-6">
                    <FormFieldSection
                      id="nzBusinessCreditIndicator"
                      inputClassName="is-12"
                      label={nzBusinessScoreInput.description}
                      checkboxProps={{
                        isChecked: isNzBusinessChecked,
                        isDisabled:
                          !isNZScoreRequired ||
                          !isCompanyEntitySelected ||
                          disabled,
                        onCheck: setNzBusinessChecked,
                      }}
                    >
                      <BorderedTextField
                        type="number"
                        id="nzBusinessCreditIndicator"
                        register={register('nzBusinessCreditIndicator')}
                        disabled={
                          !isNZScoreRequired || !isNzBusinessChecked || disabled
                        }
                        customProps={{ withBottomMargin: false }}
                        error={!!errors.nzBusinessCreditIndicator}
                        helperText={get(
                          errors,
                          'nzBusinessCreditIndicator.message',
                          ''
                        )}
                      />
                    </FormFieldSection>
                  </div>
                )}
                {nzPersonalScoreInput && (
                  <div className="column is-6">
                    <FormFieldSection
                      id="nzPersonalCreditIndicator"
                      inputClassName="is-12"
                      label={nzPersonalScoreInput.description}
                      checkboxProps={{
                        isChecked: isNzPersonalChecked,
                        isDisabled:
                          !isNZScoreRequired ||
                          !isPersonalCreditCheckEnabled ||
                          disabled,
                        onCheck: setNzPersonalChecked,
                      }}
                    >
                      <BorderedTextField
                        type="number"
                        id="nzPersonalCreditIndicator"
                        register={register('nzPersonalCreditIndicator')}
                        disabled={
                          !isNZScoreRequired || !isNzPersonalChecked || disabled
                        }
                        error={!!errors.nzPersonalCreditIndicator}
                        helperText={get(
                          errors,
                          'nzPersonalCreditIndicator.message',
                          ''
                        )}
                        customProps={{ withBottomMargin: false }}
                      />
                    </FormFieldSection>
                  </div>
                )}
              </div>
              <div className="columns is-12">
                {auBusinessScoreInput && (
                  <div className="column is-6">
                    <FormFieldSection
                      id="auBusinessCreditIndicator"
                      inputClassName="is-12"
                      label={auBusinessScoreInput.description}
                      checkboxProps={{
                        isChecked: isAuBusinessChecked,
                        isDisabled:
                          !isAUScoreRequired ||
                          !isAuBusinessesEntitySelected ||
                          disabled,
                        onCheck: setAuBusinessChecked,
                      }}
                    >
                      <BorderedTextField
                        type="number"
                        id="auBusinessCreditIndicator"
                        register={register('auBusinessCreditIndicator')}
                        disabled={
                          !isAUScoreRequired || !isAuBusinessChecked || disabled
                        }
                        error={!!errors.auBusinessCreditIndicator}
                        helperText={get(
                          errors,
                          'auBusinessCreditIndicator.message',
                          ''
                        )}
                        customProps={{ withBottomMargin: false }}
                      />
                    </FormFieldSection>
                  </div>
                )}
                {auPersonalScoreInput && (
                  <div className="column is-6">
                    <FormFieldSection
                      id="auPersonalCreditIndicator"
                      inputClassName="is-12"
                      label={auPersonalScoreInput.description}
                      checkboxProps={{
                        isChecked: isAuPersonalChecked,
                        isDisabled:
                          !isAUScoreRequired ||
                          !isPersonalCreditCheckEnabled ||
                          disabled,
                        onCheck: setAuPersonalChecked,
                      }}
                    >
                      <BorderedTextField
                        id="auPersonalCreditIndicator"
                        register={register('auPersonalCreditIndicator')}
                        type="number"
                        error={!!get(errors, 'auPersonalCreditIndicator', '')}
                        helperText={get(
                          errors,
                          'auPersonalCreditIndicator.message',
                          ''
                        )}
                        disabled={
                          !isAUScoreRequired || !isAuPersonalChecked || disabled
                        }
                        customProps={{ withBottomMargin: false }}
                      />
                    </FormFieldSection>
                  </div>
                )}
              </div>
            </>
          </RuleSetSection>
          {applyMandatory1CadJudgementDefault ? (
            <Fragment>
              <RuleSetSection title="Judgments">
                <FormFieldSection
                  id="judgements"
                  label="Any application with a history of judgements will be deferred."
                />
              </RuleSetSection>
              <RuleSetSection title="Defaults">
                <FormFieldSection
                  id="defaults"
                  label="Any application with a history of defaults will be deferred."
                />
              </RuleSetSection>
            </Fragment>
          ) : (
            <Fragment>
              <RuleSetSection title="Judgements" optional>
                <FormFieldSection id="judgements">
                  <FormCheckbox
                    name="judgements"
                    control={control}
                    formControlProps={{
                      classes: { root: classes.checkboxControlRoot },
                    }}
                    formControlLabelProps={{
                      label: 'Defer applications that contain judgements.',
                    }}
                    checkboxProps={{ disabled }}
                  />
                </FormFieldSection>
              </RuleSetSection>
              <RuleSetSection title="Defaults" optional>
                <FormFieldSection id="defaults">
                  <FormCheckbox
                    name="defaults"
                    control={control}
                    formControlProps={{
                      classes: { root: classes.checkboxControlRoot },
                    }}
                    formControlLabelProps={{
                      label: 'Defer applications that have defaults.',
                    }}
                    checkboxProps={{ disabled }}
                  />
                </FormFieldSection>
              </RuleSetSection>
            </Fragment>
          )}
          <RuleSetSection title="Bankruptcy">
            <FormFieldSection
              id="bankruptcy"
              label="Any application with a history of bankruptcy will be deferred."
            />
          </RuleSetSection>
        </div>
      ) : null}
      {!hideSaveButton && (
        <Button
          type="submit"
          className="mt-6"
          text={submitButtonLabel}
          disabled={isSaveButtonDisabled}
          loading={isUpdating}
          loading_text="Saving..."
        />
      )}
    </form>
  );
};

const mapStateToProps = (state, props) => ({
  creditCheckApplicationTypes: get(
    state,
    'current_user.current_entity.attributes.credit_check_application_types',
    []
  ),
  entityCreditCheckRules: get(
    state,
    'current_user.current_entity.attributes.selected_credit_check_rules',
    {}
  ),
  selectedCreditChecks:
    state.manage_profile.settings_selected_credit_check_rules,
  currentRuleSet: state.add_ons.auto_decisions[props.rulesetIndex],
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateRuleSet,
      loadCurrentEntity,
      loadAutoDecisions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditCheckForm);
