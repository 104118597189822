//personalinformation
//summary information

import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { checkHasSectionData, getDirectorReportData } from './utils';
import styles from './summary-report-styles.css';
import { SectionContentContainer } from '../styles';
import { IDirectorReport, TDirectorReport } from './types';
import { formatDate } from 'utils/dateFormatter';

const DirectorInformation = ({
  DirectorsConsumerDetail,
}: {
  DirectorsConsumerDetail: TDirectorReport;
}) => {
  const {
    Name,
    SubscriberReference,
    EnquiryNumber,
    IssueDateTime,
    Gender,
    DateOfBirth,
    Addresses,
    KnownNames,
    LastKnownAddress,
    Judgements,
    Insolvencies,
    Defaults,
    AgeOfFile,
  } = DirectorsConsumerDetail;
  return (
    <SectionContentContainer>
      <table className={styles.table}>
        <tbody>
          <tr className={styles.row}>
            <td className={styles.td + ' ' + styles.half_col}>{Name || '-'}</td>
            <td className={styles.half_col}></td>
          </tr>
        </tbody>
      </table>
      <table className={styles.table}>
        <tbody>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}>
              Subscriber Reference
            </th>
            <td className={styles.th + ' ' + styles.col_3}>
              {SubscriberReference || '-'}
            </td>
            <td className={styles.half_col}></td>
          </tr>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}>Enquiry Number</th>
            <td className={styles.th + ' ' + styles.col_3}>
              {EnquiryNumber || '-'}
            </td>
            <td className={styles.half_col}></td>
          </tr>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}>Enquiry Date</th>
            <td className={styles.th + ' ' + styles.col_3}>
              {IssueDateTime ? formatDate(IssueDateTime, 'DD/MM/YYYY') : '-'}
            </td>
            <td className={styles.half_col}></td>
          </tr>
        </tbody>
      </table>

      <p>Personal Information</p>

      <table className={styles.table}>
        <tbody>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}>Name</th>
            <td className={styles.th + ' ' + styles.col_3}>{Name || '-'}</td>
            <td className={styles.half_col}></td>
          </tr>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}>Gender</th>
            <td className={styles.th + ' ' + styles.col_3}>{Gender || '-'}</td>
            <td className={styles.half_col}></td>
          </tr>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}>Date of Birth</th>
            <td className={styles.th + ' ' + styles.col_3}>
              {DateOfBirth ? formatDate(DateOfBirth, 'DD/MM/YYYY') : '-'}
            </td>
            <td className={styles.half_col}></td>
          </tr>
        </tbody>
      </table>

      {!!KnownNames && KnownNames.length > 0 && (
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <th className={styles.th + ' ' + styles.col_4}>
                Also Reported As
              </th>
              <th className={styles.th + ' ' + styles.col_2}>Date of Birth</th>
              <th className={styles.half_col}></th>
            </tr>
            {KnownNames.map((KnownName) => (
              <tr className={styles.row}>
                <td className={styles.td + ' ' + styles.col_4}>
                  {KnownName.Name || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_2}>
                  {KnownName.DateOfBirth
                    ? formatDate(KnownName.DateOfBirth, 'DD/MM/YYYY')
                    : '-'}
                </td>
                <td className={styles.half_col}></td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <table className={styles.table}>
        <tbody>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_4}>
              Latest Known Address
            </th>
            <th className={styles.th + ' ' + styles.col_2}>
              Date First Loaded
            </th>
            <th className={styles.half_col}></th>
          </tr>
          <tr className={styles.row}>
            <td className={styles.td + ' ' + styles.col_4}>
              {LastKnownAddress.Address || '-'}
            </td>
            <td className={styles.td + ' ' + styles.col_2}>
              {LastKnownAddress.DateFirstLoaded
                ? formatDate(LastKnownAddress.DateFirstLoaded, 'DD/MM/YYYY')
                : '-'}
            </td>
            <td className={styles.half_col}></td>
          </tr>
        </tbody>
      </table>
      {!!Addresses && Addresses.length > 0 && (
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <th className={styles.th + ' ' + styles.col_4}>
                Other Known Previous Address
              </th>
              <th className={styles.th + ' ' + styles.col_2}>
                Date First Loaded
              </th>
              <th className={styles.half_col}></th>
            </tr>
            {Addresses.map((address) => (
              <tr className={styles.row}>
                <td className={styles.td + ' ' + styles.col_4}>
                  {address.Address || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_2}>
                  {address.DateFirstLoaded
                    ? formatDate(address.DateFirstLoaded, 'DD/MM/YYYY')
                    : '-'}
                </td>
                <td className={styles.half_col}></td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <p>Sumary Information</p>
      <table className={styles.table}>
        <tbody>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}>
              File Creation Date
            </th>
            <td className={styles.th + ' ' + styles.col_3}>
              {IssueDateTime ? formatDate(IssueDateTime, 'DD/MM/YYYY') : '-'}
            </td>
            <td className={styles.half_col}></td>
          </tr>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}>Age of File</th>
            <td className={styles.th + ' ' + styles.col_3}>
              {AgeOfFile || '-'}
            </td>
            <td className={styles.half_col}></td>
          </tr>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}>Insolvencies</th>
            <td className={styles.th + ' ' + styles.col_3}>
              {Insolvencies || '-'}
            </td>
            <td className={styles.half_col}></td>
          </tr>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}>Judgements</th>
            <td className={styles.th + ' ' + styles.col_3}>
              {Judgements || '-'}
            </td>
            <td className={styles.half_col}></td>
          </tr>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}>Defaults</th>
            <td className={styles.th + ' ' + styles.col_3}>
              {Defaults || '-'}
            </td>
            <td className={styles.half_col}></td>
          </tr>
        </tbody>
      </table>
    </SectionContentContainer>
  );
};

const DirectorReportContent = ({ directorReportData }) => {
  const { DirectorsConsumerDetails = [] } = directorReportData;
  return (
    <div>
      {DirectorsConsumerDetails.map(
        (DirectorsConsumerDetail: TDirectorReport) => (
          <DirectorInformation
            DirectorsConsumerDetail={DirectorsConsumerDetail}
          />
        )
      )}
    </div>
  );
};

const DirectorReport = ({ isAllExpanded, data }) => {
  const directorReportData: IDirectorReport = getDirectorReportData(data);
  const hasSectionData = checkHasSectionData(directorReportData);

  return (
    <ReviewContainer
      subHeading="Director Report"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <DirectorReportContent directorReportData={directorReportData} />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default DirectorReport;
