import get from 'lodash.get';
import AddonRuleModel from 'models/AddonRuleModel';
import ApplicationModel from 'models/ApplicationModel';
import LeadModel from 'models/LeadModel';
import BorderedAutocomplete from 'modules/shared/components/inputs/BorderedAutocomplete';
import BorderedTextArea from 'modules/shared/components/inputs/BorderedTextArea';
import Button from 'modules/shared/components/inputs/Button';
import React from 'react';

import { IApplicationApproverState } from './hooks/useIUFApproverState';
import styled from 'styled-components';
import { FEATURE_FLAGS } from 'conf';
import sortByDate from 'utils/sortByDate';

const showImprovedNotes = FEATURE_FLAGS.FEATURE_FLAG_IMPROVED_NOTES;

const ApprovalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

function IUFApprover({
  approvalNotes,
  currentUserIsSelectedApprover,
  error,
  formattedUsers,
  handleApproverChange,
  handleSubmit,
  isIUFApproved,
  isTagging,
  owner,
  savedApprover,
  selectedApprover,
  setApprovalNotes,
  iufAddonRule,
  isDisabled,
}: IApplicationApproverState & {
  owner: ApplicationModel | LeadModel;
  iufAddonRule: AddonRuleModel;
  isDisabled: boolean;
}) {
  if (isIUFApproved) {
    const iufApproval = get(owner, 'iufApproval');
    const histories = get(iufApproval, 'iufApprovalHistories');
    const notes = iufApproval.approvalNotes;
    const iufVersion = get(iufAddonRule, 'formattedRuleLabel');

    const sortedHistories = sortByDate({
      dateAttribute: 'createdAt',
      direction: 'descending',
      records: histories || [],
    });

    const latestHistoryRecord = sortedHistories[0];

    return (
      <div className="columns">
        <div className="column is-4">
          <hr />
          <ApprovalContentContainer>
            <p>{latestHistoryRecord && latestHistoryRecord.formattedContent}</p>
            <p>{`Following ${iufVersion}`}</p>
            {showImprovedNotes && notes && (
              <BorderedTextArea
                disabled
                darkerDisabledText
                label="IUF approval notes"
                value={notes}
              />
            )}
            {!showImprovedNotes && <p>{notes || 'No notes.'}</p>}
          </ApprovalContentContainer>
        </div>
      </div>
    );
  }

  const tagLabel = savedApprover ? 'Retag' : 'Tag';

  return (
    <form className="columns is-multiline">
      <div className="column is-6">
        <BorderedAutocomplete
          options={formattedUsers}
          textFieldProps={{
            error: Boolean(error),
            helperText: error,
            label: `${tagLabel} approver`,
            name: 'approver',
          }}
          onChange={handleApproverChange}
          getOptionLabel={(option) => option.label}
          value={selectedApprover}
          controlled
          disabled={isDisabled}
        />
      </div>
      <div className="column is-6">
        <Button
          disabled={isTagging || isDisabled}
          loading={isTagging}
          type="button"
          text={tagLabel}
          onClick={() => handleSubmit('tag')}
        />
      </div>
      {currentUserIsSelectedApprover && (
        <div className="column is-12">
          <div className="columns is-multiline">
            <div className="column is-6">
              <BorderedTextArea
                label="Approval notes"
                name="approval_notes"
                onChange={(e) => setApprovalNotes(e.target.value)}
                value={approvalNotes}
                disabled={isDisabled}
              />
            </div>
          </div>
        </div>
      )}
    </form>
  );
}

export default IUFApprover;
