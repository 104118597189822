import api from 'api';
import get from 'lodash.get';
import { IOnSetValuesCallbackAttributes } from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/types';
import { processError } from 'utils';
import isBlank from 'utils/isBlank';

export const NZBN_LENGTH = 13;

const onResetFields = (
  onSetValuesCallback?: (attributes: IOnSetValuesCallbackAttributes) => void
): void => {
  if (onSetValuesCallback) {
    onSetValuesCallback({
      companyName: '',
      companyNumber: '',
      entityName: '',
      legalType: '',
      registeredAt: '',
      tradingName: '',
    });
  }
};

const onInvalidBusinessNumber = ({
  onEmptyResultCallback,
  onEndEvent,
  onSetValuesCallback,
}: {
  onEmptyResultCallback?: () => void;
  onEndEvent?: () => void;
  onSetValuesCallback?: (attributes: IOnSetValuesCallbackAttributes) => void;
}) => {
  onResetFields(onSetValuesCallback);

  if (onEmptyResultCallback) {
    onEmptyResultCallback();
  }

  if (onEndEvent) {
    onEndEvent();
  }
};

export const onRetrieveDetailsWithBusinessNumber = ({
  businessNumber,
  onError,
  onEmptyResultCallback,
  onEndEvent,
  onSetConsumerIdToJoin,
  onSetValuesCallback,
  onStartEvent,
  disabledEntityTypes,
  onBusinessNumberEntityDisabled,
}: {
  businessNumber: string;
  onError: (error: any) => void;
  onEmptyResultCallback?: () => void;
  onEndEvent?: () => void;
  onSetConsumerIdToJoin?: (
    consumerId: string,
    belongsToEntity: boolean
  ) => void;
  onSetValuesCallback?: (attributes: IOnSetValuesCallbackAttributes) => void;
  onStartEvent?: () => void;
  disabledEntityTypes?: string[];
  onBusinessNumberEntityDisabled?: (legalType) => void;
}): void => {
  if (businessNumber.length < NZBN_LENGTH) {
    onResetFields(onSetValuesCallback);
    return;
  }

  if (onStartEvent) {
    onStartEvent();
  }

  const searchAPI = api('company_search');
  searchAPI.companyDetails(
    businessNumber,
    (result) => {
      if (onSetValuesCallback) {
        const attributes = get(result, 'data.data.attributes', {});

        if (isBlank(attributes)) {
          onInvalidBusinessNumber({
            onEmptyResultCallback,
            onEndEvent,
            onSetValuesCallback,
          });

          return;
        }

        if (onSetConsumerIdToJoin) {
          const belongsToEntity = get(result, 'data.belongs_to_entity', false);
          const consumerId = get(
            result,
            'data.data.relationships.entity.data.id'
          );

          onSetConsumerIdToJoin(consumerId, belongsToEntity);
        }

        const attributesToSet = {
          companyName: '',
          companyNumber: (attributes.company_number || '') as string,
          entityName: (attributes.name || '') as string,
          legalType: (attributes.entity_type || '') as string,
          registeredAt: (attributes.registration_date || '') as string,
        };

        if (attributes.entity_type === 'company') {
          attributesToSet['companyName'] = (attributes.name || '') as string;
        }

        onSetValuesCallback(attributesToSet);

        if (
          disabledEntityTypes?.includes(attributes.entity_type) &&
          onBusinessNumberEntityDisabled
        ) {
          onBusinessNumberEntityDisabled(attributes.entity_type);
        }
      }

      if (onEndEvent) {
        onEndEvent();
      }
    },
    (error) => {
      console.error(error);

      const { errorMessage, httpStatusCode } = processError(error);

      if (httpStatusCode === 400) {
        onError && onError(errorMessage);
        onEndEvent && onEndEvent();
        return;
      }

      onInvalidBusinessNumber({
        onEmptyResultCallback,
        onEndEvent,
        onSetValuesCallback,
      });
    },
    {
      params: {
        region: 'NZ',
      },
    }
  );
};
