import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const StyledIcon = styled(SvgIcon)`
  color: var(--main-color);
`;

export const PrimaryApplicationIcon = (props) => (
  <StyledIcon {...props}>
    <rect
      x="3.79407"
      y="0.514038"
      width="20"
      height="20"
      rx="1"
      fill="currentColor"
    />
    <rect
      x="1.00256"
      y="3.98853"
      width="18"
      height="18"
      rx="0.5"
      fill="white"
      stroke="currentColor"
    />
  </StyledIcon>
);
