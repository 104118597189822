import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import OverlayButtonsList from 'modules/shared/components/top/Overlay/overlay-buttons';
import RoundButton from 'modules/shared/components/top/Overlay/RoundButton';
import Button from 'modules/shared/components/inputs/Button';
import React, { ReactElement, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { FormValues } from '../types';
import {
  Backdrop,
  CheckboxContainer,
  CheckboxWrapper,
  ChecklistPreview,
  CloseWrapper,
  Container,
  Header,
  TitleWrapper,
  Wrapper,
} from './styles';
import { IconButton } from '@material-ui/core';

function CustomerChecklist({
  checklist,
  customisedMessage,
  links,
}: FormValues): ReactElement {
  const [selection, setSelection] = useState<string[]>([]);

  function toggleSelection(label: string) {
    if (selection.includes(label)) {
      return setSelection(selection.filter((item) => item !== label));
    }

    setSelection([...selection, label]);
  }

  const buttonList = OverlayButtonsList(true);

  return (
    <ChecklistPreview>
      <Backdrop />
      <Container>
        <Header>
          {buttonList.map(({ buttonName, buttonLabel }) => (
            <RoundButton
              key={buttonName}
              label={buttonLabel}
              sideBarName={buttonName}
              forcedActive={buttonName === 'checklist'}
            />
          ))}
        </Header>
        <Wrapper>
          <CloseWrapper>
            <IconButton>
              <CloseIcon color="action" />
            </IconButton>
          </CloseWrapper>
          <div className="columns is-multiline mt-5">
            <div className="column is-12">
              <TitleWrapper>Checklist</TitleWrapper>
              <p>
                {customisedMessage
                  ? customisedMessage
                  : 'Welcome! Please make sure you have the documents below.'}
              </p>
            </div>
            {checklist.length > 0 && (
              <div className="column is-12">
                <CheckboxWrapper className="columns is-multiline">
                  {checklist.map(({ label }, index) => (
                    <div
                      className="column is-12"
                      key={`checklist-checkbox-render-${index}`}
                    >
                      <CheckboxContainer>
                        <SquareCheckbox
                          key={`checklist-item-${index}`}
                          label={label}
                          checkboxId={`checklist-item-${index}`}
                          handleChange={() => toggleSelection(label!)}
                          checked={selection.includes(label!)}
                        />
                      </CheckboxContainer>
                    </div>
                  ))}
                </CheckboxWrapper>
              </div>
            )}
            {links.length > 0 && (
              <div className="column is-12">
                <TitleWrapper>Links</TitleWrapper>
                {links.map(({ label, value }, index) => (
                  <div key={`link-render-${index}`}>
                    <a href={value} target="_blank" rel="noopener noreferrer">
                      {label || value}
                    </a>
                  </div>
                ))}
              </div>
            )}
            <Button text="Got it!" type="button" />
          </div>
        </Wrapper>
      </Container>
    </ChecklistPreview>
  );
}

export default CustomerChecklist;
