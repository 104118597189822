import { watchtowerAlertTheme } from 'modules/shared/helpers/colorPalettes';
import { Icon } from 'mui-latest';
import styled from 'styled-components';
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined';

export const StatisticBlocksWrapper = styled.div`
  display: flex;
  gap: 17px;
`;

const alertTypeColorMap = {
  internal: watchtowerAlertTheme.internal_alert,
  external: watchtowerAlertTheme.external_alert,
};
export const AlertNameWrapper = styled.span`
  background-color: ${(props: { type?: string }) =>
    props.type ? alertTypeColorMap[props.type] : 'initial'};
  padding: 3px 5px;
  border-radius: 5px;
`;

export const HeaderWithTootltip = styled.span`
  display: flex;
  gap: 10px;
  align-items: flex-start;
`;

export const RowIcon = styled(Icon)`
  overflow: visible !important;
  margin-top: -2px;
`;

export const AccountMonitoringIconContainer = styled.div`
  display: inline-block;
  position: relative;
  left: 10px;
  top: 4px;
  svg {
    color: var(--main-color);
  }
`;

export const AccountMonitoringIcon = styled(AssignmentLateOutlinedIcon)`
  width: 16px;
  height: 16px;
  color: var(--main-color);
`;

interface IconWrapperProps {
  disabled?: boolean;
}

export const IconWrapper = styled.div<IconWrapperProps>`
  color: ${(props) => (props.disabled ? '#c9c9c9' : 'var(--main-color)')};

  svg {
    color: ${(props) => (props.disabled ? '#c9c9c9' : 'var(--main-color)')};
  }
`;

export const UngroupIconWrapper = styled.span`
  svg {
    visibility: visible;

    rect,
    path {
      fill: var(--main-color) !important;
    }
  }
`;

export const CollapsibleAlertNameWrapper = styled.div`
  margin-left: 20px;
  display: flex;
  cursor: ${(props: { disabled?: boolean }) =>
    props.disabled ? 'default' : 'pointer'};
  overflow: hidden;
`;

export const AggregateExposureWrapper = styled.p`
  border-top: 1px solid #c9c9c9;
  max-width: 220px;
  padding-top: 5px;
  span {
    font-weight: 600 !important;
    font-style: italic;
  }
`;

export const Divider = styled.hr`
  border-top: solid 1px #c9c9c9;
  margin: -10px 0 3px 37px;
  width: 65%;
`;

export const LinkedApplicationWrapper = styled.span`
  cursor: pointer;
  color: var(--main-color);
  margin-left: 37px;
  &:hover {
    color: var(--main-color-active);
  }
`;

export const LimitUngroupWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 220px;
`;
