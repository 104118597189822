import FixedContent from 'modules/shared/components/containers/FixedContent';
import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import getAccountTypeName from 'utils/getAccountTypeName';
import React from 'react';
import { FEATURE_FLAGS } from 'conf';

const CompanyInformation = (props) => {
  const { application, sectionHeader } = props;

  return (
    <FixedContent
      header={sectionHeader ? sectionHeader : 'Business information'}
    >
      <GridContent>
        <div>
          <LabeledContent
            label="Company name"
            content={application.companyName}
          />
          <LabeledContent
            label="Account type"
            content={
              application.customApplicationTypeName ||
              getAccountTypeName(application.applicationType)
            }
          />
          <LabeledContent
            label={application.businessNumberLabel}
            content={application.businessNumber}
          />
          <LabeledContent
            label="Business start date"
            content={application.formattedRegisteredAt}
          />
          <LabeledContent
            label="Number of staff"
            content={application.staffCount}
          />
        </div>
        <div>
          <LabeledContent
            label="Business type"
            content={application.formattedLegalType}
          />
          <LabeledContent
            label="Business location"
            content={application.formattedRegion}
          />
          <LabeledContent
            label={application.companyNumberLabel}
            content={application.companyNumber}
          />
          <LabeledContent
            label="Business phone number"
            content={application.contactPhoneNumber}
          />
        </div>
      </GridContent>
    </FixedContent>
  );
};

const EntityName = (props) => {
  const { application } = props;

  if (application.region !== 'AU') {
    return null;
  }

  return (
    <LabeledContent label="Entity name" content={application.companyName} />
  );
};

const NonCompanyInformation = (props) => {
  const { application, sectionHeader } = props;

  return (
    <FixedContent
      header={sectionHeader ? sectionHeader : 'Business information'}
    >
      <GridContent>
        <EntityName application={application} />
        <LabeledContent
          label={application.tradingNameLabel}
          content={application.tradingName}
        />
        <LabeledContent
          label={application.applicantNameLabel}
          content={application.trustees}
          isVisibleWhenEmpty={application.isApplicantNameVisible}
        />
        <LabeledContent
          label="Business type"
          content={application.formattedLegalType}
        />
        <LabeledContent
          label="Account type"
          content={
            application.customApplicationTypeName ||
            getAccountTypeName(application.applicationType)
          }
        />

        <LabeledContent
          label={application.businessNumberLabel}
          content={application.businessNumber}
          isVisibleWhenEmpty={application.isAustraliaNumberVisible}
        />
        <LabeledContent
          label={application.companyNumberLabel}
          content={application.companyNumber}
          isVisibleWhenEmpty={application.isAustraliaNumberVisible}
        />

        <LabeledContent
          label="Business location"
          content={application.formattedRegion}
        />
        <LabeledContent
          label="Business start date"
          content={application.formattedRegisteredAt}
          isVisibleWhenEmpty={false}
        />

        <LabeledContent
          label="Business phone number"
          content={application.contactPhoneNumber}
          isVisibleWhenEmpty={false}
        />
        <LabeledContent
          label="Number of staff"
          content={application.staffCount}
          isVisibleWhenEmpty={false}
        />
      </GridContent>
    </FixedContent>
  );
};

const SoleTraderInformation = (props) => {
  const { application, sectionHeader } = props;

  return (
    <FixedContent
      header={sectionHeader ? sectionHeader : 'Business information'}
    >
      <GridContent>
        <EntityName application={application} />
        <LabeledContent
          label="Sole trader name"
          content={application.soleTraderName}
        />
        <LabeledContent
          label={application.tradingNameLabel}
          content={application.tradingName}
        />
        <LabeledContent
          label={application.applicantNameLabel}
          content={application.trustees}
          isVisibleWhenEmpty={application.isApplicantNameVisible}
        />
        <LabeledContent
          label="Business type"
          content={application.formattedLegalType}
        />
        <LabeledContent
          label="Account type"
          content={
            application.customApplicationTypeName ||
            getAccountTypeName(application.applicationType)
          }
        />

        <LabeledContent
          label={application.businessNumberLabel}
          content={application.businessNumber}
          isVisibleWhenEmpty={application.isAustraliaNumberVisible}
        />
        <LabeledContent
          label={application.companyNumberLabel}
          content={application.companyNumber}
          isVisibleWhenEmpty={application.isAustraliaNumberVisible}
        />

        <LabeledContent
          label="Business location"
          content={application.formattedRegion}
        />
        <LabeledContent
          label="Business start date"
          content={application.formattedRegisteredAt}
          isVisibleWhenEmpty={false}
        />

        <LabeledContent
          label="Business phone number"
          content={application.contactPhoneNumber}
          isVisibleWhenEmpty={false}
        />
      </GridContent>
    </FixedContent>
  );
};

const PersonalInformation = (props) => {
  const { application, sectionHeader } = props;

  return (
    <FixedContent header={sectionHeader ? sectionHeader : 'Customer details'}>
      <GridContent>
        <div>
          <LabeledContent
            label="Account name"
            content={application.formattedBusinessName}
          />
          <LabeledContent
            label="Account type"
            content={
              application.customApplicationTypeName ||
              getAccountTypeName(application.applicationType)
            }
          />
          <LabeledContent
            label="Business location"
            content={application.formattedRegion}
          />
        </div>
        <div>
          <LabeledContent
            label="Entity type"
            content={application.formattedLegalType}
          />
          <LabeledContent
            label="Personal type"
            content={application.formattedPersonalType}
            isVisibleWhenEmpty={false}
          />
        </div>
      </GridContent>
    </FixedContent>
  );
};

const BusinessInformation = (props) => {
  const { application } = props;

  if (application.legalType === 'company') {
    return <CompanyInformation {...props} />;
  }

  if (application.legalType === 'personal') {
    return <PersonalInformation {...props} />;
  }

  if (
    FEATURE_FLAGS.FEATURE_FLAG_UPDATED_SOLE_TRADER &&
    application.legalType === 'sole_trader' &&
    application.requiresSignatories
  ) {
    return <SoleTraderInformation {...props} />;
  }

  return <NonCompanyInformation {...props} />;
};

export default BusinessInformation;
