import get from 'lodash.get';
import ApplicationModel from 'models/ApplicationModel';
import LeadModel from 'models/LeadModel';
import Buttons from 'modules/shared/components/containers/Buttons';
import Button from 'modules/shared/components/inputs/Button';
import FileWidgetSmall from 'modules/shared/components/widgets/interactive/FileWidgetSmall';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';

import { CompleteWidget } from '../Additional';
import { IIUFProps } from '.';
import ApplicationIUFInformation from './ApplicationIUFInformation';
import useIUFApproverState from './hooks/useIUFApproverState';
import useIUFState from './hooks/useIUFState';
import IUFApprover from './IUFApprover';
import TradingNamesIUFInformation from './TradingNamesIUFInformation';

function IUFView(
  props: IIUFProps & {
    editOn: () => void;
    owner: ApplicationModel | LeadModel;
  }
): ReactElement {
  const { application, editOn, lead, owner } = props;
  const iufAddonRule = (application || lead).iufAddonRule;
  const { isIUFCompleted } = useIUFState(owner);
  const displayApprover = isIUFCompleted && owner.isIUFApprovalEnabled;

  const iufApproverState = useIUFApproverState(props);
  const {
    currentUserIsSelectedApprover,
    handleSubmit,
    isApproving,
    isIUFApproved,
  } = iufApproverState;

  const file = get(owner, 'iufAddonAnswers.file');
  const attachmentConfig = get(owner, 'iufAddonRule.config.account_level', {});

  // TS throws error here because isShadowApplication is not defined in ApplicationModel.
  // But the property will be there in the API response.
  // The conditional is required because IUF can be rendered in
  // either VCF or Lead details page.
  // When in Lead details page, `application` will be undefined.
  // @ts-ignore
  const { isShadowApplication } = application || {};

  return (
    <>
      <ApplicationIUFInformation
        answersKey="account_level"
        owner={owner}
        header="Internal fields"
      >
        {file && (
          <>
            <p>{attachmentConfig.question || 'Attachment'}</p>
            <FileWidgetSmall
              completeWidget={<CompleteWidget file={file} />}
              disableChangeButton
              file={file}
              viewSrc={file}
            />
          </>
        )}
      </ApplicationIUFInformation>
      <TradingNamesIUFInformation owner={owner} />
      <ApplicationIUFInformation
        answersKey="pricing"
        owner={owner}
        header="Pricing"
      >
        {displayApprover && (
          <IUFApprover
            owner={owner}
            {...iufApproverState}
            iufAddonRule={iufAddonRule}
            isDisabled={isShadowApplication}
          />
        )}
      </ApplicationIUFInformation>
      {!isIUFApproved && (
        <Buttons spacingDirection="right">
          <Button
            type="button"
            text="Edit"
            onClick={editOn}
            disabled={isShadowApplication}
          />
          {currentUserIsSelectedApprover && (
            <Button
              disabled={isApproving || isShadowApplication}
              loading={isApproving}
              type="button"
              text="Approve"
              onClick={() => handleSubmit('approve')}
              white
            />
          )}
        </Buttons>
      )}
    </>
  );
}

export default connect()(IUFView);
