import { TERMS_AND_CONDITIONS, THIRD_PARTY_CONSENT_LABEL } from 'constants';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip.js';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import PdfReader from 'modules/shared/components/widgets/static/pdfReader';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { customTermsUrl } from 'utils/extractAttachmentUrl';
import flattenArray from 'utils/flattenArray';

import { setValue, updateAuthorisation } from '../actions';
import Confirm from './Confirm';
import styles from './css/Section.css';
import ReviewTerms from 'modules/consumer-onboarding/components/review/ReviewTerms';
import { FEATURE_FLAGS } from 'conf';
import { addFlaggedObject } from 'conf/flaggingHelpers';
import { Typography } from '@material-ui/core';
import getApplicableAntiFraudRulesForAuthorisationFlow from 'utils/anti-fraud/getApplicableAntiFraudRulesForAuthorisationFlow';

const isNewTermsAndConditionsEnabled = FEATURE_FLAGS.FEATURE_FLAG_UPDATED_TNCS;

const CHECKBOX_TERMS_BY_SIGNATORY = {
  antiFraud: ['antiFraudIdHeld'],
  guarantor: isNewTermsAndConditionsEnabled
    ? ['guarantor_liability', 'guarantor_capacity']
    : ['guarantor'],
  paperless: [
    'paperless',
    'paperlessOver18',
    'paperlessAuthorised',
    'paperlessDiscloseInformation',
  ],
  supplier: ['supplier'],
  ...addFlaggedObject('FEATURE_FLAG_GUARANTOR_FLOW_AGREEMENTS', {
    agreements: ['agreement1', 'agreement2'],
  }),
};

class Terms extends Component {
  constructor() {
    super();
    this.state = {
      antiFraudIdHold: false,
      guarantor: false,
      hasCheckedAllBoxes: false,
      hasScrolled: true,
      paperless: false,
      paperlessAuthorised: false,
      paperlessDiscloseInformation: false,
      paperlessOver18: false,
      showBoxesHint: false,
      showScrollHint: false,
      supplier: false,
      thirdPartyCheckConsent: false,
      agreement1: false,
      agreement2: false,
    };
  }

  declineAll() {
    const {
      dispatch,
      hasCardholder,
      hasGuarantor,
      hasPaperless,
      params,
      toLastSection,
    } = this.props;
    if (hasGuarantor) dispatch(setValue('guarantorApproved', false));
    if (hasCardholder) dispatch(setValue('cardholderApproved', false));
    if (hasPaperless) dispatch(setValue('paymentApproved', false));

    dispatch(
      updateAuthorisation(params.authorisation_id, () => {
        toLastSection();
      })
    );
  }

  checkBox(name) {
    const checkboxValue = !this.state[name];

    if (this.validateAllBoxes(name, checkboxValue)) {
      this.setState({ hasCheckedAllBoxes: true });
      this.setState({ showBoxesHint: false });
    } else {
      this.setState({ hasCheckedAllBoxes: false });
    }
    this.setState({ [name]: checkboxValue });
  }

  validateTerms() {
    const { toNextSection } = this.props;
    const { hasScrolled, hasCheckedAllBoxes } = this.state;
    if (!hasScrolled) {
      this.setState({ showScrollHint: true });
    }
    if (!hasCheckedAllBoxes) {
      this.setState({ showBoxesHint: true });
    }
  }

  validateAllBoxes(boxName, value) {
    const {
      termList,
      requiresIdentificationImage,
      requiresIdentificationSection,
    } = this.props;
    const isAllTermsAccepted = termList.map((term) =>
      this.isSignatoryTermsAccepted(term, boxName, value)
    );
    if (
      (requiresIdentificationImage && requiresIdentificationSection) ||
      !FEATURE_FLAGS.FEATURE_FLAGS_THIRD_PARTY_LOGIC_TERMS_UPDATE
    ) {
      isAllTermsAccepted.push([
        boxName === 'thirdPartyCheckConsent'
          ? value
          : this.state.thirdPartyCheckConsent,
      ]);
    }
    return flattenArray(isAllTermsAccepted).every((isAccepted) => isAccepted);
  }

  // This function returns an array of boolean values wherein all items in the
  // array should be true if the user accepted all the terms for a specific
  // signatory, i.e. applicant, guarantor or paperless
  isSignatoryTermsAccepted(signatoryTerm, boxName, value) {
    if (!this.props.termList.includes(signatoryTerm)) {
      return [];
    }

    return CHECKBOX_TERMS_BY_SIGNATORY[signatoryTerm].map((checkbox) =>
      checkbox === boxName ? value : this.state[checkbox]
    );
  }

  onScrollToBottom() {
    if (!this.state.hasScrolled) {
      this.setState({ hasScrolled: true });
      this.setState({ showScrollHint: false });
    }
  }

  renderPdf() {
    const { termList, customTermsConfigs, region } = this.props;

    const termsListWithPdf =
      FEATURE_FLAGS.FEATURE_FLAG_GUARANTOR_FLOW_AGREEMENTS
        ? termList.filter((term) => term !== 'agreements')
        : termList;

    const termUrls = [];
    termsListWithPdf.forEach((termType) => {
      let termUrl = defaults[termType][region];

      if (customTermsConfigs[termType].uses_custom_terms) {
        termUrl = customTermsUrl(customTermsConfigs[termType].custom_terms).url;
      }
      termUrls.push(termUrl);
    });
    return (
      <PdfReader
        urls={termUrls}
        onScrollToBottom={this.onScrollToBottom.bind(this, event)}
      />
    );
  }

  renderCheckBoxes() {
    return [
      ...this.renderSupplierCheckboxes(),
      ...[
        isNewTermsAndConditionsEnabled ? [] : this.renderGuarantorCheckboxes(),
      ],
      ...this.renderPaperlessCheckboxes(),
      this.renderThirdPartyIdCheckConsentCheckbox(),
    ];
  }

  renderSupplierCheckboxes() {
    if (this.props.termList.includes('supplier')) {
      return [this.renderSupplierTermsCheckbox()];
    }

    return [];
  }

  renderSupplierTermsCheckbox() {
    const supplierLegalName = this.props.supplierLegalName;
    const oldLabel = `I have read ${supplierLegalName} Terms and Conditions of Sale and agree that all purchases from ${supplierLegalName} will be on those Terms`;
    const newLabel = `I have read ${supplierLegalName} Terms and Conditions of Sale and/or Credit and agree that all purchases from ${supplierLegalName} will be on those Terms`;
    const label = isNewTermsAndConditionsEnabled ? newLabel : oldLabel;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'supplier')}
      >
        <SquareCheckbox label={label} checked={this.state.supplier} />
      </div>
    );
  }

  renderGuarantorCheckboxes() {
    if (this.props.termList.includes('guarantor')) {
      return isNewTermsAndConditionsEnabled
        ? [this.renderGuarantorTermsCheckbox()]
        : [this.renderGuarantorTermsCheckbox_deprecated()];
    }

    return [];

    return [];
  }

  renderGuarantorTermsCheckbox_deprecated() {
    const supplierLegalName = this.props.supplierLegalName;
    const label = `I have read and understood the guarantor Terms and Conditions and I
                   acknowledge that ${supplierLegalName} recommend that I obtain
                   independent legal advice as to the effect of this guarantee
                   and my potential liability as guarantor, and I confirm that
                   I have elected not to obtain such independent advice and
                   have agreed to provide the guarantee in favour of the
                   applicant.`;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'guarantor')}
      >
        <SquareCheckbox label={label} checked={this.state.guarantor} />
      </div>
    );
  }

  renderGuarantorTermsCheckbox() {
    const supplierName = this.props.supplierLegalName;
    const guarantor_liability = `I acknowledge that ${supplierName} recommend that I obtain independent legal advice as to the effect of this Guarantee and my liability as a guarantor`;

    const guarantor_capacity =
      'I acknowledge and agree to the Terms of the Guarantee and/or Indemnity and am signing in my personal capacity as guarantor';

    return (
      <Fragment>
        <div
          className={styles.row}
          onClick={this.checkBox.bind(this, 'guarantor_liability')}
        >
          <SquareCheckbox
            label={guarantor_liability}
            checked={this.state.guarantor_liability}
          />
        </div>
        <div
          className={styles.row}
          onClick={this.checkBox.bind(this, 'guarantor_capacity')}
        >
          <SquareCheckbox
            label={guarantor_capacity}
            checked={this.state.guarantor_capacity}
          />
        </div>
      </Fragment>
    );
  }

  renderPaperlessCheckboxes() {
    if (this.props.termList.includes('paperless')) {
      return [
        this.renderPaperlessAuthorised(),
        this.renderPaperlessDisclaimer(),
        this.renderPaperlessDiscloseInformation(),
        this.renderPaperlessOver18(),
      ];
    }

    return [];
  }

  renderIdHoldDisclaimer() {
    const supplierLegalName = this.props.supplierLegalName;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'antiFraudIdHold')}
      >
        <SquareCheckbox
          label={`I confirm that ${supplierLegalName} has the right to hold my ID documents on file until my identity has been confirmed`}
          checked={this.state.antiFraudIdHold}
        />
      </div>
    );
  }

  renderThirdPartyIdCheckConsentCheckbox() {
    if (FEATURE_FLAGS.FEATURE_FLAGS_THIRD_PARTY_LOGIC_TERMS_UPDATE) {
      const { requiresIdentificationImage, requiresIdentificationSection } =
        this.props;
      if (!requiresIdentificationImage || !requiresIdentificationSection)
        return null;
    }

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'thirdPartyCheckConsent')}
      >
        <SquareCheckbox
          label={THIRD_PARTY_CONSENT_LABEL}
          checked={this.state.thirdPartyCheckConsent}
        />
      </div>
    );
  }

  renderPaperlessDisclaimer() {
    const supplierLegalName = this.props.supplierLegalName;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'paperless')}
      >
        <SquareCheckbox
          label={`I have read and understood the terms and conditions of the direct debit authority for ${supplierLegalName}`}
          checked={this.state.paperless}
        />
      </div>
    );
  }

  renderPaperlessDiscloseInformation() {
    const supplierLegalName = this.props.supplierLegalName;

    const oldLabel = `I understand that ${supplierLegalName} will not use, store or disclose my information except in accordance with ${supplierLegalName} Privacy Policy and/or Terms and Conditions of Sale`;
    const newLabel = `I understand that ${supplierLegalName} will not use, store or disclose my information except in accordance with ${supplierLegalName} Privacy Policy and/or Terms and Conditions of Sale and/or Credit`;

    const label = isNewTermsAndConditionsEnabled ? newLabel : oldLabel;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'paperlessDiscloseInformation')}
      >
        <SquareCheckbox
          label={label}
          checked={this.state.paperlessDiscloseInformation}
        />
      </div>
    );
  }

  renderPaperlessOver18() {
    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'paperlessOver18')}
      >
        <SquareCheckbox
          label="I am not less than 18 years of age"
          checked={this.state.paperlessOver18}
        />
      </div>
    );
  }

  renderPaperlessAuthorised() {
    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'paperlessAuthorised')}
      >
        <SquareCheckbox
          label="I am authorised to sign this direct debit"
          checked={this.state.paperlessAuthorised}
        />
      </div>
    );
  }

  toggleAgreement(agreementId) {
    const agreementName = `agreement${agreementId}`;
    this.checkBox(agreementName);
  }

  render() {
    const { hasScrolled, hasCheckedAllBoxes } = this.state;

    return (
      <div>
        <div className={styles.container}>
          <section className={styles.section}>
            <div className={styles.row}>
              <div className={styles.panel}>
                <PanelTitle text={defaults.title} />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.content}>
                <PopperTooltip
                  title={tooltips.scroll}
                  placement="bottom-end"
                  open={this.state.showScrollHint}
                >
                  {this.renderPdf()}
                </PopperTooltip>
                <PopperTooltip
                  title={tooltips.checkBox}
                  placement="bottom-start"
                  open={this.state.showBoxesHint}
                >
                  <div className={styles.checkbox_container}>
                    {isNewTermsAndConditionsEnabled && (
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ marginLeft: '-5px', marginTop: '25px' }}
                      >
                        General Acknowledgement and Agreement
                      </Typography>
                    )}
                    {this.renderCheckBoxes()}
                  </div>
                </PopperTooltip>

                {isNewTermsAndConditionsEnabled &&
                  this.props.termList.includes('guarantor') && (
                    <Fragment>
                      <hr className={styles.divider}></hr>
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ marginLeft: '-5px', marginTop: '25px' }}
                      >
                        Guarantor Acknowledgement and Agreement
                      </Typography>
                      {this.renderGuarantorCheckboxes()}
                    </Fragment>
                  )}

                {FEATURE_FLAGS.FEATURE_FLAG_GUARANTOR_FLOW_AGREEMENTS && (
                  <Fragment>
                    <hr className={styles.divider}></hr>
                    <ReviewTerms
                      agreements={[
                        { id: 1, agreed: this.state.agreement1 },
                        { id: 2, agreed: this.state.agreement2 },
                      ]}
                      region={this.props.region}
                      toggleAgreement={this.toggleAgreement.bind(this)}
                    />
                    <hr className={styles.divider}></hr>
                  </Fragment>
                )}
                <Confirm
                  {...this.props}
                  renderWithoutSection
                  validateTerms={this.validateTerms.bind(this)}
                  agreedToTerms={hasScrolled && hasCheckedAllBoxes}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const defaults = {
  guarantor: {
    AU: TERMS_AND_CONDITIONS.AU.guarantor,
    NZ: TERMS_AND_CONDITIONS.NZ.guarantor,
  },
  paperless: {
    AU: TERMS_AND_CONDITIONS.AU.paperless,
    NZ: TERMS_AND_CONDITIONS.NZ.paperless,
  },
  supplier: {
    AU: TERMS_AND_CONDITIONS.AU.supplier,
    NZ: TERMS_AND_CONDITIONS.NZ.supplier,
  },
  title: 'Terms and conditions',
};

const tooltips = {
  checkBox: 'Please agree to all the declarations.',
  scroll: 'You must scroll through the Ts & Cs before you can agree to them.',
};

export default connect((state, ownProps) => {
  const authorisation = state.authorisation;
  const applicableAntifraudRules =
    getApplicableAntiFraudRulesForAuthorisationFlow(authorisation);
  const { requiresIdentificationSection, requiresIdentificationImage } =
    applicableAntifraudRules || {};

  const termList = ['supplier'];
  if (FEATURE_FLAGS.FEATURE_FLAG_GUARANTOR_FLOW_AGREEMENTS) {
    termList.push('agreements');
  }

  if (ownProps.hasGuarantor && authorisation.guarantorApproved) {
    termList.push('guarantor');
  }

  if (ownProps.hasPaperless && authorisation.paymentApproved) {
    termList.push('paperless');
  }

  const applicationAttributes = authorisation.data.application.attributes;
  const customTermsConfigs = {
    guarantor: {
      custom_terms: applicationAttributes.custom_guarantor_terms,
      uses_custom_terms: applicationAttributes.uses_custom_guarantor_terms,
    },
    paperless: {
      custom_terms: applicationAttributes.custom_paperless_terms,
      uses_custom_terms: applicationAttributes.uses_custom_paperless_terms,
    },
    supplier: {
      custom_terms: applicationAttributes.custom_terms,
      uses_custom_terms: applicationAttributes.uses_custom_supplier_terms,
    },
  };

  return {
    requiresIdentificationSection,
    requiresIdentificationImage,
    customTermsConfigs,
    region: applicationAttributes.region,
    termList,
    updating: authorisation.updating,
  };
})(Terms);
