import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from 'modules/dashboard/components/styles';
import Button from 'modules/shared/components/inputs/Button';
import PopperToolTip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import React from 'react';

const ButtonWithTooltip = (props) => {
  const { buttonText, onClick, tooltip, disabled } = props;

  return (
    <Button
      white
      text={
        <PopperToolTip
          extraComponent={<div className="has-text-left">{tooltip}</div>}
        >
          <div>
            <span className="mr-1">{buttonText}</span>
            <Icon>
              <FontAwesomeIcon icon={['fas', 'question-circle']} />
            </Icon>
          </div>
        </PopperToolTip>
      }
      handleClick={onClick}
      disabled={disabled}
    />
  );
};

export default ButtonWithTooltip;
