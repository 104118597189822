import React from 'react';
import { SvgIcon } from '@material-ui/core';
import IconShadowApplication from 'images/svgs/icon-shadow-application.svg';
import styled from 'styled-components';

const StyledIcon = styled(SvgIcon)`
  color: #9f9f9f;
`;

export const ShadowApplicationIcon = (props) => (
  <StyledIcon {...props}>
    <rect
      x="1.00256"
      y="3.51581"
      width="20"
      height="20"
      rx="0.5"
      fill="white"
      stroke="currentColor"
    />
    <rect
      x="4.29407"
      y="0.541382"
      width="20"
      height="20"
      rx="0.5"
      fill="currentColor"
      stroke="#9F9F9F"
    />
  </StyledIcon>
);
