import styled from 'styled-components';

export const Wrapper = styled.div`
  // padding: 0 6px;
`;

export const BoldItalicLabel = styled.span`
  font-weight: bold !important;
  font-style: italic;
`;

export const IdSelectionWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

export const IdCaptureWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;
