import { Collapse } from '@material-ui/core';
import commonStyles from 'css/shared/PipelineStyles.css';
import { ActionWrapper } from 'modules/new-applications/components/application-actions/Watchtower/Alerts/styles';
import ActionModal from 'modules/new-applications/components/application-actions/Watchtower/Modal/ActionModal';
import Button from 'modules/shared/components/inputs/Button';
import TableListSkeleton from 'modules/shared/components/v2/Skeletons/TableListSkeleton';
import Pagination from 'modules/shared/components/widgets/interactive/Pagination';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import useCollapsibleTableRowState from 'modules/shared/hooks/useCollapsibleTableRowState';
import React, { useEffect, useState } from 'react';
// @ts-ignore browserHistory exist on react-router
import { browserHistory } from 'react-router';
import IconUngroup from 'images/svgs/ungroup.svg';

import {
  AccountMonitoringIcon,
  AccountMonitoringIconContainer,
  AggregateExposureWrapper,
  CollapsibleAlertNameWrapper,
  IconWrapper,
  Divider,
  LinkedApplicationWrapper,
  LimitUngroupWrapper,
  UngroupIconWrapper,
} from '../styles';

import {
  AlertRowProps,
  WatchtowerTableRowProps,
  WatchtowerTableProps,
} from '../types';

const goToApplication = (applicationId) => {
  browserHistory.push({
    pathname: `/dashboard/applications/${applicationId}/watchtower`,
  });
};

const AlertRow = ({
  alertName,
  alertDate,
  collapsible,
  linkedApplications,
  removeFromGroup,
}: AlertRowProps) => {
  const [clickedApplicationId, setClickedApplicationId] = useState<
    string | undefined
  >();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const { icon, isOpen, onToggleIsOpen } = useCollapsibleTableRowState({
    useAddIcon: true,
  });

  const handleCollapse = () => {
    if (!collapsible) return;

    if (!isLoaded) setIsLoaded(true);
    onToggleIsOpen();
  };

  return (
    <React.Fragment>
      <div className={commonStyles.grid_table_4}>
        <CollapsibleAlertNameWrapper
          disabled={!collapsible}
          onClick={handleCollapse}
        >
          <IconWrapper disabled={!collapsible}>{icon}</IconWrapper>
          {alertName}
        </CollapsibleAlertNameWrapper>
        <div>{alertDate}</div>
        <div></div>
        <div></div>
      </div>
      {collapsible && (
        <Collapse in={isOpen}>
          {(isOpen || isLoaded) && (
            <React.Fragment>
              <Divider />
              {linkedApplications.map((linkedApplication, index) => {
                const { customer, alert_date, tier, limit, application_id } =
                  linkedApplication;
                return (
                  <div
                    className={commonStyles.grid_table_4}
                    key={'linked-app-' + application_id + index}
                  >
                    <LinkedApplicationWrapper
                      onClick={() => goToApplication(application_id)}
                    >
                      {customer}
                    </LinkedApplicationWrapper>
                    <div>{alert_date}</div>
                    <div>{tier}</div>
                    <LimitUngroupWrapper>
                      <p>{limit}</p>
                      <a
                        onClick={() => setClickedApplicationId(application_id)}
                      >
                        <PopperTooltip
                          placement="top"
                          title="Remove application from group"
                        >
                          <UngroupIconWrapper>
                            <IconUngroup />
                          </UngroupIconWrapper>
                        </PopperTooltip>
                      </a>
                    </LimitUngroupWrapper>
                  </div>
                );
              })}
              {clickedApplicationId && (
                <ActionModal
                  onConfirm={() => {
                    removeFromGroup(clickedApplicationId);
                    setClickedApplicationId(undefined);
                  }}
                  onCancel={() => setClickedApplicationId(undefined)}
                  actionType={'remove_group'}
                />
              )}
            </React.Fragment>
          )}
        </Collapse>
      )}
    </React.Fragment>
  );
};

const CollapseContent = ({
  aggregateExposure,
  applicationId,
  alerts,
  removeFromGroup,
}) => {
  return (
    <div className={commonStyles.details}>
      <div className={commonStyles.manage_account_button}>
        <Button
          text="Manage account"
          onClick={() => goToApplication(applicationId)}
        />
      </div>
      <div className={commonStyles.alert_row_container}>
        {alerts.map((alertData, index) => {
          const { alert_name, alert_date, linked_applications } = alertData;
          const hasLinkedApplication = linked_applications.length > 0;
          return (
            <AlertRow
              alertDate={alert_date}
              alertName={alert_name}
              collapsible={hasLinkedApplication}
              linkedApplications={linked_applications}
              key={'alert-row-' + applicationId + index}
              removeFromGroup={removeFromGroup}
            />
          );
        })}
        <div className={commonStyles.grid_table_4}>
          <div></div>
          <div></div>
          <div></div>
          <div>
            <AggregateExposureWrapper>
              <span>{aggregateExposure}</span> Aggregate exposure
            </AggregateExposureWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

function WatchtowerTableRow(props: WatchtowerTableRowProps) {
  const { rowData, current, index, setCurrentIndex, removeFromGroup } = props;
  const {
    grouped,
    application_id,
    customer,
    alert_date,
    tier,
    limit,
    alerts,
    aggregate_exposure,
    account_monitoring,
  } = rowData;

  const isCollapsible = grouped;
  const [isLoaded, setIsLoaded] = useState(false);

  const { icon, isOpen, onToggleIsOpen } = useCollapsibleTableRowState();

  const onClick = () => {
    setCurrentIndex(index);
    onToggleIsOpen();
  };

  useEffect(() => {
    if (!current && isOpen) onToggleIsOpen();
    if (current && !isOpen) onToggleIsOpen();
  }, [current]);

  useEffect(() => {
    if (current && isOpen) {
      setIsLoaded(true);
    }
  }, [current, isOpen]);

  return (
    <div>
      <div className={commonStyles.grid_table_4}>
        <div
          className={
            commonStyles.default_row +
            ' ' +
            (isCollapsible
              ? commonStyles.expandable_col
              : commonStyles.non_expandable_col)
          }
          onClick={onClick}
        >
          {isCollapsible && <IconWrapper>{icon}</IconWrapper>}
          <span
            className={commonStyles.pointer}
            onClick={() => {
              if (isCollapsible) return;
              goToApplication(application_id);
            }}
          >
            {customer}
            {account_monitoring && (
              <PopperTooltip
                placement="top"
                title="Account monitoring (Account status/Review date)  apply. Go to VCF for more information"
              >
                <AccountMonitoringIconContainer>
                  <AccountMonitoringIcon />
                </AccountMonitoringIconContainer>
              </PopperTooltip>
            )}
          </span>
        </div>
        <div>{alert_date}</div>
        <div>{tier}</div>
        <div>{limit}</div>
      </div>
      {isCollapsible && (
        <Collapse in={current && isOpen}>
          {((current && isOpen) || isLoaded) && (
            <CollapseContent
              removeFromGroup={removeFromGroup}
              aggregateExposure={aggregate_exposure}
              alerts={alerts}
              applicationId={application_id}
            />
          )}
        </Collapse>
      )}
    </div>
  );
}

const WatchtowerTableHeader = () => (
  <div
    className={`${commonStyles.grid_table_4} ${commonStyles.table_header_sticky}`}
  >
    <div>Customer</div>
    <div>Alert date</div>
    <div>Tier</div>
    <div>Limit</div>
  </div>
);

const WatchtowerTable = (props: WatchtowerTableProps) => {
  const queryParams = new URLSearchParams(window.location.search);
  const defaultOpen = queryParams.has('default_open');

  const { tableData = [], loading } = props;
  const [currentIndex, setCurrentIndex] = useState(defaultOpen ? 0 : undefined);

  if (loading) {
    return <TableListSkeleton />;
  }

  return (
    <div className={commonStyles.table_content}>
      <WatchtowerTableHeader />
      {tableData.map((rowData, index) => (
        <WatchtowerTableRow
          {...props}
          key={`application-${index}-${rowData.application_id}`}
          rowData={rowData}
          setCurrentIndex={setCurrentIndex}
          current={currentIndex === index}
          index={index}
        />
      ))}
    </div>
  );
};

const DEFAULT_PER_PAGE = 25;

const PipelineTable = (props) => {
  const { meta = {}, updateSearchParams } = props;

  return (
    <React.Fragment>
      <WatchtowerTable {...props} />
      <Pagination
        dataLength={meta.total}
        currentPage={meta.page}
        todosPerPage={meta.per}
        resourceName="Entries"
        optionViewPage={[
          { label: '25', value: DEFAULT_PER_PAGE },
          { label: '50', value: 50 },
        ]}
        handleClick={(page) => updateSearchParams({ page })}
        handleClickTodosPerPage={(per) => updateSearchParams({ per })}
      />
    </React.Fragment>
  );
};

export default PipelineTable;
