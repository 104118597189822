import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { checkHasSectionData, getShareholderAffiliations } from './utils';
import styles from './summary-report-styles.css';
import { SectionContentContainer } from '../styles';
import { TShareholderAffiliate } from './types';

const ShareholderAffiliationsContent = ({
  ShareholdersAffiliations = [],
}: {
  ShareholdersAffiliations: TShareholderAffiliate[];
}) => {
  return (
    <SectionContentContainer>
      <table className={styles.table}>
        <thead>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_3}>Name</th>
            <th className={styles.th + ' ' + styles.col_3}>Company</th>
            <th className={styles.th + ' ' + styles.col_2}>Number</th>
            <th className={styles.th + ' ' + styles.col_2}>NZBN</th>
            <th className={styles.th + ' ' + styles.col_2}>Status</th>
          </tr>
        </thead>
        <tbody>
          {!!ShareholdersAffiliations &&
            ShareholdersAffiliations.length > 0 &&
            ShareholdersAffiliations.map((affiliate) => (
              <tr className={styles.row}>
                <td className={styles.td + ' ' + styles.col_3}>
                  {affiliate.ShareholderName || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_3}>
                  {affiliate.CompanyName || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_2}>
                  {affiliate.CompanyNumber || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_2}>
                  {affiliate.NZBN || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_2}>
                  {affiliate.RegistrationStatus || '-'}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </SectionContentContainer>
  );
};
const ShareholderAffiliations = ({ isAllExpanded, data }) => {
  const shareholderAffiliations = getShareholderAffiliations(data);
  const { ShareholdersAffiliations } = shareholderAffiliations;
  const hasSectionData =
    checkHasSectionData(shareholderAffiliations) &&
    ShareholdersAffiliations.length;

  return (
    <ReviewContainer
      subHeading="Shareholder Affiliations"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <ShareholderAffiliationsContent
          ShareholdersAffiliations={ShareholdersAffiliations}
        />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default ShareholderAffiliations;
