import api from 'api';
import Axios from 'axios';
import get from 'lodash.get';
import BorderedAutocomplete from 'modules/shared/components/inputs/BorderedAutocomplete';
import {
  ThemeProvider as MuiThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import { debounce } from 'debounce';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
// @ts-ignore-next-line: TS is not able to find `browserHistory` as an exported value of react-router but definitely there is
import { browserHistory } from 'react-router';
import styled from 'styled-components';
import { COLORS } from 'variables/theme';

const DEBOUNCE_DELAY = 1000; // in milliseconds

export const NavSearchInputWrapper = styled.div`
  margin: 15px;
  max-width: 400px;
  flex-grow: 1;
  display: flex;
`;

export const MavSearchOptionWrapper = styled.div`
  display: flex;
  flex: 1 75px;
`;

export const NavOptionConsumerDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface NavOptionTextProps {
  subtitle?: boolean;
  mainTitle?: boolean;
  capitalize?: boolean;
}

export const NavOptionText = styled.span<NavOptionTextProps>`
  ${(props) => (props.subtitle ? `color: ${COLORS.mediumGrey};` : '')}
  ${(props) => (props.mainTitle ? 'font-weight: 500 !important;' : '')}
  ${(props) => (props.capitalize ? `text-transform: capitalize;` : '')}
`;

const useStyles = makeStyles({
  root: {
    flex: 1,
  },
  popper: {
    zIndex: 200001,
  },
  inputRoot: {
    backgroundColor: 'white',
  },
});

const NavSearchOption = ({ consumerName, consumerEmail, type }) => {
  return (
    <MavSearchOptionWrapper>
      <NavOptionConsumerDetails>
        <NavOptionText mainTitle>{consumerName}</NavOptionText>
        <NavOptionText subtitle>{consumerEmail}</NavOptionText>
      </NavOptionConsumerDetails>
      <NavOptionText capitalize subtitle>
        {type}
      </NavOptionText>
    </MavSearchOptionWrapper>
  );
};

const redirect = (id, type) => {
  if (type === 'leads') {
    browserHistory.push({ pathname: `/dashboard/leads/${id}` });
  } else {
    browserHistory.push({
      pathname: `/dashboard/applications/${id}`,
    });
  }
};

type NavOption = {
  type: string;
  id: string;
  value: string;
  label: string;
  consumerName: string;
  consumerEmail: string;
};

type AnyObject = { [key: string]: any };

const getConsumerOptionDetails = (
  consumerData: AnyObject,
  type: string
): NavOption => {
  return {
    type,
    id: consumerData.id,
    value: consumerData.id,
    label: consumerData.attributes.consumer_name,
    consumerName: consumerData.attributes.consumer_name,
    consumerEmail: consumerData.attributes.consumer_contact_email,
  };
};

const getOptionsData = (response: AnyObject[]): NavOption[] => {
  const [salesResponse, customerResponse, leadsResponse] = response;
  const salesResponseArray = salesResponse.data.data || [];
  const customerResponseArray = customerResponse.data.data || [];
  const leadsResponseArray = leadsResponse.data.data || [];

  const salesOptions: NavOption[] = salesResponseArray.map(
    (consumerData: AnyObject) => getConsumerOptionDetails(consumerData, 'sales')
  );
  const customerOptions: NavOption[] = customerResponseArray.map(
    (consumerData: AnyObject) =>
      getConsumerOptionDetails(consumerData, 'customer')
  );
  const leadsOptions: NavOption[] = leadsResponseArray.map(
    (consumerData: AnyObject) => getConsumerOptionDetails(consumerData, 'leads')
  );

  return [...salesOptions, ...customerOptions, ...leadsOptions];
};

const reportParams = {
  acting_as_supplier: true,
  page: 1,
  per: 5,
  limit_type: 'requested_limits',
  status: 'started',
};

const leadsParams = {
  page: 1,
  per_page: 5,
  active_tab: 'total',
  entity_id: 'All',
  entity_type: 'All',
  from: '2016-12-24T16:29:57.710Z',
  initiator: 'All',
  'order[created_at]': 'desc',
  region: 'All',
  state: 'All',
  user: 'All',
};

const NavSearch = ({ currentUser }) => {
  const classes = useStyles();

  const [input, setInput] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [searchOptions, setSearchOptions] = useState<NavOption[]>([]);

  const accessToken = currentUser.access_token;
  const entityId = get(currentUser, 'current_entity.id');
  const reportApi = api('report_applications', accessToken, entityId);
  const leadsApi = api('leads', accessToken, entityId);

  const getSearchOptions = (currentInput: string | undefined) => {
    setLoading(true);
    const salesRequest = reportApi.getApplications({
      params: {
        ...reportParams,
        pipeline: 'sales',
        key_word: currentInput,
      },
    });
    const customerRequest = reportApi.getApplications({
      params: {
        ...reportParams,
        pipeline: 'customer',
        key_word: currentInput,
      },
    });
    const leadsRequest = leadsApi.getLeads({
      ...leadsParams,
      search: currentInput,
    });

    Axios.all([salesRequest, customerRequest, leadsRequest]).then((res) => {
      setSearchOptions(getOptionsData(res));
      setLoading(false);
    });
  };

  const onInputTextChange = useCallback(
    debounce(getSearchOptions, DEBOUNCE_DELAY),
    []
  );

  useEffect(() => {
    if (input) {
      onInputTextChange(input);
    } else {
      setSearchOptions([]);
    }
  }, [input]);

  const handleClick = (optionData: NavOption) => {
    if (!optionData) return;
    const { type, id } = optionData;
    if (!id) {
      return;
    }
    redirect(id, type);
    setInput(input);
  };

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <NavSearchInputWrapper>
        <BorderedAutocomplete
          freeSolo
          openOnFocus
          blurOnSelect
          options={searchOptions || []}
          inputValue={input}
          onInputChange={(e) => {
            setInput(e.target.value || '');
          }}
          onChange={(e, newValue) => {
            handleClick(newValue);
            setInput((prevValue) => prevValue);
          }}
          loading={loading}
          getOptionLabel={() => input}
          classes={{
            root: classes.root,
            popper: classes.popper,
            inputRoot: classes.inputRoot,
          }}
          getOptionSelected={() => false}
          renderOption={(optionData: NavOption) => (
            <NavSearchOption
              consumerName={optionData.consumerName}
              consumerEmail={optionData.consumerEmail}
              type={optionData.type}
              key={optionData.id}
            />
          )}
        />
      </NavSearchInputWrapper>
    </MuiThemeProvider>
  );
};

export default NavSearch;
