import React from 'react';
import {
  TableCell,
  TableRow as MuiTableRow,
  Collapse,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import { Add, ChevronRight, Remove } from '@material-ui/icons';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined';
import RapidTransferIconSvg from 'images/svgs/table-flag-rapid-transfer.svg';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
// @ts-ignore
import { browserHistory } from 'react-router';

import Button from 'modules/shared/components/inputs/Button';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import { COLORS } from 'variables/theme';
import { FEATURE_FLAGS } from 'conf';
import ApplicationName from 'modules/reporting/components/Reporting/ApplicationName';
import { PrimaryApplicationIcon } from 'modules/shared/components/svg/PrimaryApplicationIcon';
import { ShadowApplicationIcon } from 'modules/shared/components/svg/ShadowApplicationIcon';

const isWatchtowerEnabled = FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER;

const StyledRow = styled(MuiTableRow)<{ $bg?: string }>`
  background-color: ${(props) => props.$bg || 'initial'};
  td {
    border-bottom: none;
  }
`;

const CustomerCell = styled(TableCell)`
  cursor: pointer;
  :hover {
    background-color: ${COLORS.lightGrey};
  }
  transition: background-color 200ms;

  .icon {
    color: var(--main-color);
    position: relative;
    top: 4.5px;
  }

  @media screen and (min-width: 992px) {
    width: 40%;
  }
`;

const CollapseRow = styled(StyledRow)`
  td {
    padding: 0 !important;
  }
  .collapse-inner {
    padding: 15px 24px;
    background: #f6f6f6;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ViewGroupWrapper = styled.span`
  @media screen and (max-width: 991px) {
    display: none !important;
  }
`;

const IconsContainer = styled.div`
  display: inline-flex;
  position: relative;
  left: 10px;
  top: 4px;
  gap: 6px;
  svg {
    width: 18px !important;
    height: 18px !important;
    position: relative;
    top: -2px;
  }
  svg.primary-application-icon,
  svg.shadow-application-icon {
    width: 14px !important;
    height: 14px !important;
    top: 0;
  }
`;

const AccountMonitoringIcon = styled(AssignmentLateOutlinedIcon)`
  color: var(--main-color);
`;

const SalesChannelIcon = styled(PhoneOutlinedIcon)`
  color: var(--main-color);
`;

const RapidTransferIcon = styled(RapidTransferIconSvg)`
  color: var(--main-color);
`;

const WatchtowerAlertNames = ({ children }: { children: string }) => (
  <Typography color="error">{children}</Typography>
);

type TableRowProps = {
  id: string;
  expandable?: boolean;
  expanded?: boolean;
  toggleExpand: () => void;
  customerName: string;
  approvedLimit: string;
  date: string;
  tier: string;
  rowColor?: string;
  isFromBulkUpload: boolean;
  showViewGroup: boolean;
  internalWatchtowerAlertNames?: string;
  externalWatchtowerAlertNames?: string;
  callCentreActivity: boolean;
  hasAccountMonitoring: boolean;
  applicationVersion?: string;
  isWatchtowerActive?: boolean;
  isShadowApplication: boolean;
  hasShadowApplication: boolean;
};

export const TableRow = (props: TableRowProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const {
    id,
    expanded = false,
    expandable = false,
    toggleExpand,
    customerName,
    approvedLimit,
    date,
    tier,
    isFromBulkUpload,
    rowColor,
    showViewGroup,
    internalWatchtowerAlertNames,
    externalWatchtowerAlertNames,
    callCentreActivity,
    hasAccountMonitoring,
    applicationVersion,
    isWatchtowerActive,
    isShadowApplication,
    hasShadowApplication,
  } = props;

  const useDefaultWatchtowerLanding =
    isWatchtowerActive && isWatchtowerEnabled ? 'watchtower' : '';

  const handleClickName = () => {
    expandable
      ? toggleExpand()
      : browserHistory.push(
          `/dashboard/applications/${id}/${useDefaultWatchtowerLanding}`
        );
  };

  let Icon = ChevronRight;
  if (expandable) {
    Icon = expanded ? Remove : Add;
  }

  const getWatchtowerUrl = () => {
    const encodedCustomerName = encodeURIComponent(customerName);
    return `/dashboard/watchtower?key_word=${encodedCustomerName}&page=1&per=25&default_open`;
  };

  return (
    <>
      <StyledRow $bg={rowColor}>
        <CustomerCell onClick={handleClickName}>
          <Icon className="icon" />
          <ApplicationName
            applicationName={customerName}
            versionNumber={applicationVersion || '1'}
          />
          <IconsContainer>
            {hasShadowApplication && (
              <PrimaryApplicationIcon className="primary-application-icon" />
            )}
            {isShadowApplication && (
              <ShadowApplicationIcon className="shadow-application-icon" />
            )}
            {isFromBulkUpload && (
              <PopperTooltip placement="top" title="Rapid transfer">
                <div>
                  <RapidTransferIcon />
                </div>
              </PopperTooltip>
            )}
            {callCentreActivity && <SalesChannelIcon />}
            {hasAccountMonitoring && (
              <PopperTooltip
                placement="top"
                title="Account monitoring (Account status/Review date)  apply. Go to VCF for more information"
              >
                <AccountMonitoringIcon />
              </PopperTooltip>
            )}
          </IconsContainer>
        </CustomerCell>
        <TableCell>{approvedLimit}</TableCell>
        {isDesktop && (
          <>
            <TableCell>{date}</TableCell>
            <TableCell>{tier}</TableCell>
          </>
        )}
      </StyledRow>
      {expandable && (
        <CollapseRow>
          <TableCell colSpan={4}>
            <Collapse
              classes={{ wrapperInner: 'collapse-inner' }}
              in={expanded}
            >
              {isWatchtowerEnabled && (
                <>
                  {externalWatchtowerAlertNames && (
                    <WatchtowerAlertNames>
                      {externalWatchtowerAlertNames}
                    </WatchtowerAlertNames>
                  )}
                  {internalWatchtowerAlertNames && (
                    <WatchtowerAlertNames>
                      {internalWatchtowerAlertNames}
                    </WatchtowerAlertNames>
                  )}
                </>
              )}
              <ButtonsContainer>
                <Button
                  text="Manage account"
                  link={`/dashboard/applications/${id}/${useDefaultWatchtowerLanding}`}
                />
                {showViewGroup && (
                  <ViewGroupWrapper>
                    <Button white text="View group" link={getWatchtowerUrl()} />
                  </ViewGroupWrapper>
                )}
              </ButtonsContainer>
            </Collapse>
          </TableCell>
        </CollapseRow>
      )}
    </>
  );
};
