import api from 'api';
import { FEATURE_FLAGS } from 'conf';
import debounce from 'debounce';
import { isEqual } from 'lodash';
import UserModel from 'models/UserModel';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
// @ts-ignore-next-line: TS is not able to find `browserHistory` as an exported value of react-router but definitely there is
import { browserHistory } from 'react-router';

import Filter from './components/Filter';
import PipelineTableDeprecated from './components/PipelineTable_deprecated';
import StatisticsBlock from './components/StatisticsBlock';
import {
  createAlertData,
  createQuery,
  defaultSearchParams,
  filterFetchedAlert,
} from './helpers';
import { DashboardPageContainer } from 'modules/shared/components';

const getDefaultBlock = (query) => {
  const { unactioned, alert_type, watchtower_alert_status } = query;
  if (watchtower_alert_status === 'blocked') {
    return 'blocked';
  }
  if (unactioned === 'true' && alert_type === 'internal') {
    return 'unactioned_internal';
  }
  if (unactioned === 'true' && alert_type === 'external') {
    return 'unactioned_external';
  }

  return null;
};

export const useWatchtowerPipelineState = (props) => {
  const { currentUser, location } = props;

  const [tableData, setTableData] = useState<{ tableRows: any }>({
    tableRows: [],
  });
  const [summary, setSummary] = useState<any>({});
  const [meta, setMeta] = useState<any>({});
  const [searchParams, internalUpdateSearchParams] = useState<any>({
    ...defaultSearchParams,
    ...location.query,
  });

  const [activeBlock, setActiveBlock] = useState<string>(
    getDefaultBlock(location.query) || 'total'
  );

  const [loading, setLoading] = useState<boolean>(false);

  const [alertsData, setAlertsData] = useState<any>({});

  const applicationAPI = api(
    'applications',
    currentUser.accessToken,
    currentUser.currentEntity.id
  );

  const fetchAlertData = (queryParams, newActiveBlock) => {
    setLoading(true);
    applicationAPI
      .getWatchtowerPipeline_deprecated({
        ...queryParams,
      })
      .then((response) => {
        setAlertsData(filterFetchedAlert(response.data, newActiveBlock));
        setLoading(false);
      });
  };

  const debouncedFetchAlertData = useRef(debounce(fetchAlertData, 500)).current;

  useEffect(() => {
    setLoading(true);
    const queryParams = createQuery(activeBlock, searchParams);

    if (!isEqual(queryParams, location.query)) {
      browserHistory.push({
        pathname: '/dashboard/watchtower',
        query: isEqual(queryParams, defaultSearchParams) ? {} : queryParams,
        state: location.state,
      });
    }

    debouncedFetchAlertData(queryParams, activeBlock);
  }, [activeBlock]);

  useEffect(() => {
    if (activeBlock) {
      const originalSearchParams = { ...searchParams };
      let queryParams = {
        ...createQuery(activeBlock, searchParams),
        ...originalSearchParams,
      };

      if (queryParams.key_word !== location.query.key_word) {
        queryParams = {
          ...queryParams,
          page: 1,
        };
      }

      if (!isEqual(queryParams, location.query)) {
        browserHistory.push({
          pathname: '/dashboard/watchtower',
          query: isEqual(queryParams, defaultSearchParams) ? {} : queryParams,
          state: location.state,
        });
      }

      debouncedFetchAlertData(queryParams, activeBlock);
    }
  }, [searchParams]);

  useEffect(() => {
    const tableRows = createAlertData(alertsData, activeBlock);
    setTableData({
      tableRows,
    });

    const { meta } = alertsData;
    setSummary(
      meta
        ? { ...meta.summary, total_fraudulent: meta.summary.total_blocked }
        : {}
    );
    setMeta(meta ? { ...meta } : {});
  }, [alertsData, activeBlock]);

  const updateSearchParams = (newParams, includePrev = true) => {
    internalUpdateSearchParams((prevParams) => {
      if (!newParams.key_word) {
        delete prevParams['key_word'];
      }

      return {
        ...(includePrev ? prevParams : {}),
        ...newParams,
      };
    });
  };

  return {
    activeBlock,
    fetchAlertData,
    meta,
    setActiveBlock,
    summary,
    tableData,
    searchParams,
    updateSearchParams,
    loading,
  };
};

const WatchtowerPipeline = (props) => {
  if (!FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER) {
    browserHistory.push({ pathname: '/dashboard/home' });

    return null;
  }
  const {
    tableData,
    summary,
    meta,
    updateSearchParams,
    setActiveBlock,
    activeBlock,
    searchParams,
    loading,
  } = useWatchtowerPipelineState(props);

  const onClickBlock = (params) => {
    const { active_block } = params;
    setActiveBlock(active_block);
  };

  return (
    <DashboardPageContainer
      pageTitle="Watchtower"
      headerComponents={
        <React.Fragment>
          <StatisticsBlock
            summary={summary}
            activeBlock={activeBlock}
            onClickBlock={onClickBlock}
          />
          <Filter
            updateSearchParams={updateSearchParams}
            searchParams={searchParams}
            {...props}
          />
        </React.Fragment>
      }
      contentComponents={
        <PipelineTableDeprecated
          key={activeBlock}
          activeBlock={activeBlock}
          {...props}
          loading={loading}
          meta={meta}
          tableData={tableData}
          updateSearchParams={updateSearchParams}
        />
      }
    />
  );
};

export default connect((state) => {
  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
  };
})(WatchtowerPipeline);
