import React, { useState } from 'react';
import {
  WatchtowerAlertsWrapper,
  WatchtowerAlertActionContainer,
  WatchtowerFraudulentAccountWrapper,
} from './styles';
import WatchtowerAlertBucket, {
  WatchtowerAlertAction,
} from './components/WatchtowerAlertsBucket';
import WatchtowerAlertSummary from './components/WatchtowerAlertSummary';
import { isFeatureAvailable } from 'modules/shared/helpers/headquarterDetect';
import { FEATURE_FLAGS } from 'conf';
// @ts-ignore-next-line: TS is not able to find `Link` as an exported value of react-router but definitely there is
import { browserHistory } from 'react-router';
import ScrollableContent from 'modules/shared/components/containers/ScrollableContent';
import RecordHistory from 'modules/new-applications/components/RecordHistory';
import ContentWithFooter from 'modules/shared/components/containers/ContentWithFooter';
import api from 'api';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from 'mui-latest';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReportFraudulentAccountModal from './components/ReportFraudulentAccountModal';

export const WatchtowerUndesirableAccount = ({
  currentUser,
  applicationId,
  onFetchApplicationRecord,
  isAccountUndesirable,
  disabled,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const applicationAPI = api(
    'applications',
    currentUser.accessToken,
    currentUser.currentEntity.id
  );

  const actionCallback = () => {
    setIsLoading(false);
    onFetchApplicationRecord();
  };

  const handleActionAlert = () => {
    if (disabled || isLoading) return;

    const ACTION = 'undesirable';
    setIsLoading(true);
    if (isAccountUndesirable) {
      applicationAPI.applicationWatchtowerRevertAction(
        applicationId,
        ACTION,
        actionCallback
      );
    } else {
      applicationAPI.applicationWatchtowerAction(
        applicationId,
        ACTION,
        actionCallback
      );
    }
  };

  const RenderCheckbox = (
    <FormControl component="fieldset">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          disabled={disabled || isLoading}
          onClick={handleActionAlert}
          control={<Checkbox color="default" checked={isAccountUndesirable} />}
          label="Undesirable account"
          labelPlacement="end"
        />
      </FormGroup>
    </FormControl>
  );

  return (
    <div>
      {disabled ? (
        RenderCheckbox
      ) : (
        <PopperTooltip title="Marking this account as Undesirable moves it to your Undesirable bucket in Watchtower. If a related credit application is submitted (e.g., a director applies under a new entity), the VCF will be grouped.">
          {RenderCheckbox}
        </PopperTooltip>
      )}
    </div>
  );
};

export const WatchtowerFraudulentAccount = ({
  currentUser,
  applicationId,
  onFetchApplicationRecord,
  disabled,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const applicationAPI = api(
    'applications',
    currentUser.accessToken,
    currentUser.currentEntity.id
  );

  const handleActionAlert = () => {
    if (disabled) return;

    applicationAPI.applicationWatchtowerAction(
      applicationId,
      'fraudulent',
      () => onFetchApplicationRecord()
    );
    setIsModalOpen(false);
  };

  const handleModalToggle = () => {
    if (disabled) return;

    setIsModalOpen(true);
  };

  const FraudulentAccountButton = (
    <WatchtowerFraudulentAccountWrapper
      $disabled={disabled}
      onClick={handleModalToggle}
    >
      <ErrorOutlineIcon />
      <span>Report fraudulent</span>
    </WatchtowerFraudulentAccountWrapper>
  );

  return (
    <div>
      {isModalOpen && (
        <ReportFraudulentAccountModal
          onClose={() => setIsModalOpen(false)}
          onConfirm={handleActionAlert}
        />
      )}
      {disabled ? (
        FraudulentAccountButton
      ) : (
        <PopperTooltip title="You have with certainty identified that this application is fraudulent. As a result any email addresses attached to this VCF will be blocked from the entire 1Centre database, protecting you and other 1Centre Suppliers from any further threat.">
          {FraudulentAccountButton}
        </PopperTooltip>
      )}
    </div>
  );
};

const WatchtowerAlerts = ({
  applicationId,
  watchtowerAlerts,
  entityTierData,
  currentUser,
  onFetchApplicationRecord,
  isFlaggedAccount,
  isDisabledVcf,
}) => {
  const watchtowerAlertKeys = Object.keys(watchtowerAlerts || {});

  return (
    <WatchtowerAlertsWrapper>
      {watchtowerAlertKeys.map((alertKey) => (
        <WatchtowerAlertBucket
          key={`alertKey_${applicationId}`}
          alertData={watchtowerAlerts[alertKey]}
          entityTierData={entityTierData}
          applicationId={applicationId}
          currentUser={currentUser}
          onFetchApplicationRecord={onFetchApplicationRecord}
          isFlaggedAccount={isFlaggedAccount}
          isDisabledVcf={isDisabledVcf}
        />
      ))}
    </WatchtowerAlertsWrapper>
  );
};

const WatchtowerAlertsContainer = (props) => {
  const {
    application,
    watchtowerExclusions,
    currentUser,
    onFetchApplicationRecord,
  } = props;
  const watchtowerAlertSummary =
    application.watchtowerAlertSummary(watchtowerExclusions);
  const watchtowerAlertsToDisplay =
    application.getWatchtowerAlertsToDisplay(watchtowerExclusions);
  const allAlerts = application.allWatchtowerAlerts(watchtowerExclusions);
  const hasAlerts = !!allAlerts.length;
  const { entityTierData } = currentUser.currentEntity;
  const applicationId = application.id;
  const applicationLabel = application.label;
  const isFlaggedAccount = !!applicationLabel;
  const { isDisabledVcf } = application;

  if (
    !FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER ||
    !isFeatureAvailable('watchtower')
  ) {
    const baseName = location.pathname.replace('/watchtower', '');
    browserHistory.push({ pathname: baseName });

    return null;
  }

  return (
    <ContentWithFooter
      forceBottomFooter
      footer={
        <RecordHistory histories={application.watchtowerAlertHistories} />
      }
    >
      <ScrollableContent withBottomPadding>
        <WatchtowerAlertSummary
          summary={watchtowerAlertSummary}
          hasActiveAlerts={
            application.isFraudulent &&
            FEATURE_FLAGS.FEATURE_FLAG_FRAUDULENT_APP_UPDATED_VCF
              ? false
              : isFlaggedAccount ||
                (hasAlerts
                  ? application.hasActiveAlerts(watchtowerExclusions)
                  : false)
          }
        />
        <WatchtowerAlertActionContainer>
          <WatchtowerAlertAction
            label="Mark all alerts as actioned"
            currentUser={currentUser}
            applicationId={applicationId}
            onFetchApplicationRecord={onFetchApplicationRecord}
            actionAllAlert
            disabled={!hasAlerts || isDisabledVcf}
          />
          <WatchtowerUndesirableAccount
            isAccountUndesirable={applicationLabel === 'undesirable'}
            currentUser={currentUser}
            applicationId={applicationId}
            onFetchApplicationRecord={onFetchApplicationRecord}
            disabled={isDisabledVcf}
          />
          <WatchtowerFraudulentAccount
            currentUser={currentUser}
            applicationId={applicationId}
            onFetchApplicationRecord={onFetchApplicationRecord}
            disabled={isDisabledVcf}
          />
        </WatchtowerAlertActionContainer>
        {hasAlerts && (
          <WatchtowerAlerts
            applicationId={applicationId}
            watchtowerAlerts={watchtowerAlertsToDisplay}
            entityTierData={entityTierData || []}
            currentUser={currentUser}
            onFetchApplicationRecord={onFetchApplicationRecord}
            isFlaggedAccount={isFlaggedAccount}
            isDisabledVcf={isDisabledVcf}
          />
        )}
      </ScrollableContent>
    </ContentWithFooter>
  );
};

export default WatchtowerAlertsContainer;
