import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import EntityModel from 'models/EntityModel';
import UserModel from 'models/UserModel';
import ButtonGroupWrapper from 'modules/consumer-onboarding/v2/ButtonGroupWrapper';
import ColumnWrapper from 'modules/consumer-onboarding/v2/ColumnWrapper';
import ControlWrapper from 'modules/consumer-onboarding/v2/ControlWrapper';
import FormFieldWrapper from 'modules/consumer-onboarding/v2/FormFieldWrapper';
import { UserContext } from 'modules/consumer-onboarding/v2/hooks/UserContext';
import {
  EmailPrompt,
  FormWrapper,
  MobileWrapper,
  SectionHeader,
} from 'modules/consumer-onboarding/v2/styles';
import { requestVerificationCode } from 'modules/consumer-onboarding/v2/utils/verificationCode';
import useIsMobileScreenState, {
  MEDIA_QUERIES,
} from 'modules/new-applications/hooks/useIsMobileScreenState';
import Button from 'modules/shared/components/inputs/Button';
import { onToggleSidebar } from 'modules/shared/components/top/Overlay/redux/actions';
import RHFBorderedEmailField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedEmailField';
import useYupValidationResolver from 'modules/shared/hooks/useYupValidationResolver';
import React, { ReactElement, useContext, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useForm } from 'react-hook-form-latest';
import { connect } from 'react-redux';
import {
  getWatchtowerBlockedMessage,
  isWatchtowerBlock,
  processError,
} from 'utils';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
// Refer to https://github.com/jquense/yup/issues/507
import isEmail from 'validator/lib/isEmail';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';

type FormValues = {
  email: string;
};

const validationSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .required('Please enter your email')
      .email('Please enter a valid email')
      .test('is-valid', 'Please enter a valid email', (value) =>
        isEmail(value)
      ),
  });

const StartApplicationForm = (props): ReactElement => {
  const {
    applicationId,
    currentUser,
    hasInteractedWithSidebar,
    onNext,
    supplier,
    supplierChecklist,
    dispatch,
    router: { location },
    gtmId,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const userState = useContext(UserContext);
  const { email, emailFromParams, isEmailFieldDisabled, setEmail } = userState;
  const isSudoMode = isPresent(get(location, 'query.sudo_token'));

  const { control, handleSubmit, setError, setValue } = useForm<FormValues>({
    defaultValues: { email },
    mode: 'onSubmit',
    resolver: useYupValidationResolver(validationSchema),
  });

  useEffect(() => {
    if (hasInteractedWithSidebar) {
      return;
    }

    const showChecklist = supplierChecklist
      ? !isEmpty(supplierChecklist)
      : false;
    if (showChecklist) {
      dispatch(onToggleSidebar('checklist'));
    }
  }, [supplierChecklist]);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayerName: 'dataLayer',
    };

    if (gtmId) {
      TagManager.initialize({ gtmId, ...tagManagerArgs });
    }
  }, [gtmId]);

  useEffect(() => {
    let emailToSet = '';

    if (isPresent(emailFromParams)) {
      emailToSet = emailFromParams;
      setValue('email', emailToSet);
    } else if (isBlank(email) && isPresent(currentUser.email)) {
      emailToSet = currentUser.email;
      setValue('email', emailToSet);
    }

    if (isSudoMode) {
      requestVerificationCode({
        applicationId,
        email: emailToSet,
        errorCallback: (error) => console.error(error),
        successCallback: (result) => onNext(get(result, 'data', {})),
        supplierId: supplier.id,
      });
    }
  }, [
    currentUser.isUserLoggedIn,
    currentUser.email,
    emailFromParams,
    isSudoMode,
  ]);

  const handleWatchtowerError = () => {
    setError('email', {
      type: 'custom',
      message: getWatchtowerBlockedMessage('email'),
    });
  };

  const handleGenericError = (error) => {
    const { errorMessage } = processError(error);
    enqueueSnackbar(errorMessage, { variant: 'error' });
  };

  const handleError = (error) => {
    if (isWatchtowerBlock(error)) {
      handleWatchtowerError();
    } else {
      handleGenericError(error);
    }
  };

  const onSubmit = (data) => {
    const { email } = data;

    setEmail(email);

    requestVerificationCode({
      applicationId,
      email,
      errorCallback: handleError,
      successCallback: (result) => onNext(get(result, 'data', {})),
      supplierId: supplier.id,
    });
  };
  const { isMobileScreen } = useIsMobileScreenState(
    MEDIA_QUERIES.max_width_991
  );

  const header = (
    <SectionHeader>Start your {supplier.tradingName} application</SectionHeader>
  );

  const mobileHeader = (
    <MobileWrapper>
      {header}
      <EmailPrompt>Please enter your email</EmailPrompt>
    </MobileWrapper>
  );

  return (
    <ColumnWrapper>
      {isMobileScreen ? mobileHeader : header}
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <FormFieldWrapper>
          <RHFBorderedEmailField
            control={control}
            name="email"
            defaultValue={email}
            disabled={isEmailFieldDisabled}
            label="Email address"
            dataCy="bc-flow-email-input"
          />
        </FormFieldWrapper>

        <ButtonGroupWrapper>
          <ControlWrapper>
            <Button text="Next" type="submit" dataCy="bc-flow-next-button" />
          </ControlWrapper>
        </ButtonGroupWrapper>
      </FormWrapper>
    </ColumnWrapper>
  );
};

export default connect((state) => {
  const currentUser = get(state, 'current_user', {});
  const supplier = get(state, 'cob_section.supplier', {});
  const supplierChecklist = get(supplier, 'attributes.supplier_checklist', {});
  const gtmId = get(supplier, 'attributes.google_analytics_id', '');

  return {
    currentUser: UserModel.fromCurrentUser(currentUser),
    gtmId,
    hasInteractedWithSidebar: get(
      state,
      'applicationOverlayReducer.hasInteracted',
      false
    ),
    supplier: new EntityModel(supplier),
    supplierChecklist,
  };
})(StartApplicationForm);
