//Minimum accepted score to allow user to finish BC Flow
export const AUTHENTICITY_SCORE_MAP = {
  1: {
    min_passing: 0.75,
    min_accepted: 0.5,
    fail: 0.49,
  },
  2: {
    min_passing: 0.7,
    min_accepted: 0.5,
    fail: 0.49,
  },
  3: {
    min_passing: 0.65,
    min_accepted: 0.49,
    fail: 0.48,
  },
};

export const getAuthenticityOutcome_deprecated = (
  authenticityScore: any,
  minPassScore: number
) => {
  const authScore = parseFloat(authenticityScore || 0);

  if (authScore >= minPassScore) {
    return 'Pass';
  }

  if (authScore >= 0.5 && authScore < minPassScore) {
    return 'Warning';
  }

  //authScore < 0.5
  return 'Fail';
};

export const getAuthenticityScoreDetails_deprecated = (
  authenticityScore: any,
  minPassScore: number
) => {
  const authScore = parseFloat(authenticityScore || 0);

  if (authScore >= minPassScore) {
    return 'Good quality image and no signs of tampering.';
  }
  if (authScore >= 0.5 && authScore < minPassScore) {
    return 'Poor quality image and/or signs of document tampering.';
  }

  //authScore < 0.5
  return 'Document is fraudulent, unsupported or of very poor quality.';
};

export const getAuthenticityOutcome = (
  authenticityScore: any,
  authenticityScoreVersion: number
) => {
  const authScore = parseFloat(authenticityScore || 0);
  const { min_passing, min_accepted } =
    AUTHENTICITY_SCORE_MAP[authenticityScoreVersion];

  if (authScore >= min_passing) {
    return 'Pass';
  }

  if (authScore >= min_accepted && authScore < min_passing) {
    return 'Warning';
  }

  //authScore < 0.49
  return 'Fail';
};

export const getAuthenticityScoreDetails = (
  authenticityScore: any,
  authenticityScoreVersion: number
) => {
  const { min_passing, min_accepted } =
    AUTHENTICITY_SCORE_MAP[authenticityScoreVersion];

  const authScore = parseFloat(authenticityScore || 0);

  if (authScore >= min_passing) {
    return 'Good quality image and no signs of tampering.';
  }
  if (authScore >= min_accepted && authScore < min_passing) {
    return 'Poor quality image and/or signs of document tampering. Please complete your own checks to verify this ID.';
  }

  //authScore < 0.49
  return 'Document is fraudulent, unsupported or of very poor quality. Please complete your own checks to verify this ID, or ask the customer to provide a valid ID if needed.';
};
