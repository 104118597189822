/* eslint-disable max-lines */
import { makeStyles } from '@material-ui/core';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import debounce from 'debounce';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import PpsrFinancingStatementModel, {
  COLLATERAL_TYPE_OPTIONS,
} from 'models/PpsrFinancingStatementModel';
import UserModel from 'models/UserModel';
import commonStyles from 'modules/direct-debit-authority/css/DirectDebitAuthorityIndex.css';
import StatusIndicator from 'modules/new-applications/components/StatusIndicator';
import styles from 'modules/ppsr_registration/css/PPSRRegistrationIndex.css';
import NumApplicationsBlock from 'modules/reporting/components/NumApplicationsBlock';
import { ENTITY_TYPE_OPTIONS } from 'modules/reporting/constants';
import useFilterState from 'modules/reporting/hooks/useFilterState';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import TableListSkeleton from 'modules/shared/components/v2/Skeletons/TableListSkeleton';
import materialSelectStyle from 'modules/shared/components/widgets/css/material-select';
import Pagination from 'modules/shared/components/widgets/interactive/Pagination';
import AdminInnerTitle from 'modules/shared/components/widgets/static/AdminInnerTitle';
import { isHeadquarter } from 'modules/shared/helpers/headquarterDetect';
import { USER_UPDATE_PREFERENCE_SUCCESS } from 'modules/user/constants';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import isBlank from 'utils/isBlank';
import { REGION_FULL_NAMES } from 'utils/region';

import ApplicationName from '../../reporting/components/Reporting/ApplicationName';
import { DashboardPageContainer } from 'modules/shared/components';
import { PrimaryApplicationIcon } from 'modules/shared/components/svg/PrimaryApplicationIcon';
import { ShadowApplicationIcon } from 'modules/shared/components/svg/ShadowApplicationIcon';
import styled from 'styled-components';

const IconsContainer = styled.div`
  display: inline-flex;
  gap: 10px;
  align-items: center;
  position: relative;
  top: 4px;
  left: 10px;
  svg.primary-application-icon,
  svg.shadow-application-icon {
    width: 14px;
    height: 14px;
  }
`;

const LOCAL_STORAGE_KEY = 'searchParams-ppsrRegistration-';
const DEBOUNCE_INTERVAL = 1000;
const DEFAULT_PER_PAGE = 25;

const extractUserSettings = (entityId) =>
  JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_KEY}${entityId}`)) || {};

function useApplicationsState(props) {
  const { currentUser } = props;
  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [meta, setMeta] = useState({});

  const entityId = get(currentUser, 'currentEntity.id');
  const userSettings = extractUserSettings(entityId);

  const [applicationParams, setApplicationParams] = useState({
    active_tab: 'total',
    collateral_type: 'All',
    from: null,
    page: get(userSettings, 'page', 1),
    per: get(
      currentUser,
      'preferenceByCurrentEntity.ppsrRegistrationPerPage',
      DEFAULT_PER_PAGE
    ),
    search: null,
    to: null,
  });

  const onFetchPPSRRegistrations = (params) => {
    (async () => {
      setLoading(true);
      const { meta, ppsrFinancingStatements } =
        await PpsrFinancingStatementModel.fetchModulePaperlessApplications({
          accessToken: currentUser.accessToken,
          entityId,
          params,
        });
      setApplications(ppsrFinancingStatements);
      setMeta(meta);
      setLoading(false);
    })();
  };

  const onFetchFilterOptions = async () => {
    const options = await PpsrFinancingStatementModel.loadPPSRFilterOptions({
      accessToken: currentUser.accessToken,
      entityId,
    });
    setBranchOptions(options);
  };

  const updateApplicationParams = (params) => {
    const updatedParams = { ...applicationParams, ...params };
    const newApplicationParams = {};
    Object.keys(updatedParams).forEach((key) => {
      if (![null, '', 'All'].includes(updatedParams[key])) {
        newApplicationParams[key] = updatedParams[key];
      }
    });

    if (!newApplicationParams.expiry_date) {
      delete newApplicationParams.from;
      delete newApplicationParams.to;
    } else {
      newApplicationParams['from'] = params.expiry_date_from;
      newApplicationParams['to'] = params.expiry_date_to;
    }
    setApplicationParams(newApplicationParams);
    localStorage.setItem(
      `${LOCAL_STORAGE_KEY}${entityId}`,
      JSON.stringify(newApplicationParams)
    );
  };

  return {
    applicationParams,
    applications,
    branchOptions,
    loading,
    meta,
    onFetchFilterOptions,
    onFetchPPSRRegistrations,
    updateApplicationParams,
  };
}

function StatisticsBlock(props) {
  const { activeBlock, meta, onClickBlock } = props;

  const onClick = (block) => onClickBlock({ active_tab: block, page: 1 });

  return (
    <div className={commonStyles.statistics_block}>
      <NumApplicationsBlock
        color="blue"
        title="Total"
        value={meta.total || 0}
        active={activeBlock === 'total'}
        isCompact={true}
        onClick={() => onClick('total')}
      />

      <NumApplicationsBlock
        color="light-blue"
        title="Draft"
        value={meta.draft || 0}
        active={activeBlock === 'draft'}
        isCompact={true}
        onClick={() => onClick('draft')}
      />

      <NumApplicationsBlock
        color="blue"
        title="Registered"
        value={meta.registered || 0}
        active={activeBlock === 'registered'}
        isCompact={true}
        onClick={() => onClick('registered')}
      />

      <NumApplicationsBlock
        color="red"
        title="Expired"
        value={meta.expired || 0}
        active={activeBlock === 'expired'}
        isCompact={true}
        onClick={() => onClick('expired')}
      />

      <NumApplicationsBlock
        color="medium-grey"
        title="Discharged"
        value={meta.discharged || 0}
        active={activeBlock === 'discharged'}
        isCompact={true}
        onClick={() => onClick('discharged')}
      />
    </div>
  );
}

function sortedCollateralOptions() {
  return COLLATERAL_TYPE_OPTIONS.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }

    if (a.label > b.label) {
      return 1;
    }

    return 0;
  });
}

function buildCollateralTypeOptions() {
  const useStyles = makeStyles(() => {
    return {
      listSubHeaderRoot: {
        backgroundColor: 'white',
        pointerEvents: 'none',
      },
    };
  });

  const classes = useStyles();

  const collateralOptions = [
    <MenuItem value="All" key="all">
      <div className={materialSelectStyle.material_menuitem}>All</div>
    </MenuItem>,
  ];

  // TODO - Make this region filter work
  // if (region) {
  //   const filteredOptions = sortedCollateralOptions().filter(
  //     ({ region: optionRegion }) => region !== optionRegion
  //   );

  //   return [
  //     ...collateralOptions,
  //     filteredOptions,
  //   ]
  // }

  const groupedOptions = groupBy(
    sortedCollateralOptions(),
    (option) => option.region
  );

  const regionGroups = Object.keys(groupedOptions);
  for (const region of regionGroups) {
    collateralOptions.push(
      <ListSubheader classes={{ root: classes.listSubHeaderRoot }}>
        <div className="is-size-4 has-text-weight-normal">
          {REGION_FULL_NAMES[region]}
        </div>
      </ListSubheader>
    );

    for (const option of groupedOptions[region]) {
      collateralOptions.push(
        <MenuItem key={option.value} value={option.value}>
          <div className={materialSelectStyle.material_menuitem}>
            {option.label}
          </div>
        </MenuItem>
      );
    }
  }

  return collateralOptions;
}

function Filter(props) {
  const { applicationParams, branchOptions, updateApplicationParams } = props;
  const { filter, filterParams, onToggleIsOpen } =
    useFilterState(applicationParams);

  const ppsrFilters = [
    {
      key: 'location',
      label: 'Location',
      type: 'region_select',
      visible: true,
    },
    {
      key: 'collateral_type',
      label: 'Collateral type',
      options: [{ label: 'All', value: 'All' }, ...COLLATERAL_TYPE_OPTIONS],
      rawOptions: buildCollateralTypeOptions(applicationParams.region),
      type: 'dropdown',
      visible: true,
    },
    {
      key: 'purchase_money_enabled',
      label: 'Purchase money security interest (PMSI)',
      options: [
        { label: 'All', value: 'All' },
        { label: 'PMSI Applies', value: 'true' },
      ],
      type: 'dropdown',
      visible: true,
    },
    {
      isFutureDate: true,
      key: 'expiry_date',
      label: 'Expiry date',
      type: 'date_picker',
      visible: true,
    },
    {
      key: 'branch',
      label: 'Tier',
      options: [{ label: 'All', value: 'All' }, ...branchOptions],
      type: 'dropdown',
      visible: isHeadquarter(),
    },
    {
      key: 'entity_type',
      label: 'Entity type',
      options: ENTITY_TYPE_OPTIONS,
      type: 'dropdown',
      visible: true,
    },
  ];

  const onChangeSearch = (event) => {
    const value = get(event, 'target.value');
    updateApplicationParams({ page: 1, search: value });
  };

  // TODO - Review if this is needed
  // const resetSearch = () => {
  //   updateApplicationParams({ search: "" })
  //   debounce(onFetchPPSRRegistrations({ search: "" }), DEBOUNCE_INTERVAL);
  // };

  const ppsrFilter = React.cloneElement(filter, {
    dynamicFilterOptions: {
      branch_options: [],
      user_options: [],
    },
    filterType: 'ppsr',
    isStatesOptionsVisible: false,
    passedFilters: ppsrFilters,
  });

  useEffect(() => {
    updateApplicationParams(filterParams);
  }, [filterParams]);

  return (
    <Fragment>
      <div className={styles.search_container}>
        <div className={styles.search_input}>
          <BorderedTextField
            id="search"
            label="Search"
            onChange={onChangeSearch}
            value={applicationParams.search}
          />
        </div>
        <div className={styles.search_items}>
          <span
            className={`${styles.search_item} ${styles.clickable} ${styles.mobile_invisible}`}
            onClick={onToggleIsOpen}
          >
            Advanced&nbsp;
            <u>
              <b>search</b>
            </u>
          </span>
        </div>
      </div>
      {ppsrFilter}
    </Fragment>
  );
}

function formatDate({ autoRenew, expiryDate }) {
  if (isBlank(expiryDate)) {
    return '-';
  }

  if (autoRenew) {
    return `${expiryDate} (auto-renew)`;
  }

  return expiryDate;
}

function ApplicationRow(props) {
  const { application } = props;
  console.log(application);
  return (
    <div className={styles.grid_table}>
      <div className={styles.account_name}>
        <Link
          to={`/dashboard/applications/${application.applicationId}/ppsr_register`}
        >
          <ApplicationName
            applicationName={application.debtorName}
            versionNumber={application.versionNumber}
            isFloating={application.isFloating}
          />
          <IconsContainer>
            {application.hasShadowApplication && (
              <PrimaryApplicationIcon className="primary-application-icon" />
            )}
            {application.isShadowApplication && (
              <ShadowApplicationIcon className="shadow-application-icon" />
            )}
          </IconsContainer>
        </Link>
      </div>
      <div>
        <StatusIndicator
          status={application.statusColor}
          statusName={application.formattedStatus}
          withStatusName={true}
          key={`indicators-${application.id}`}
        />
      </div>
      <div>{application.region}</div>
      <div>{application.formattedFirstCollateralType}</div>
      <div>
        {formatDate({
          autoRenew: application.autoRenew,
          expiryDate: application.formattedExpiryDate,
        })}
      </div>
      <div>{application.tier}</div>
    </div>
  );
}

function ApplicationTableHeader() {
  return (
    <div className={`${styles.grid_table} ${commonStyles.table_header}`}>
      <div>Debtor name</div>
      <div>Status</div>
      <div>Location</div>
      <div>Collateral</div>
      <div>Expiry date</div>
      <div>{isHeadquarter() ? 'Tier' : 'Owner'}</div>
    </div>
  );
}

function ApplicationTable(props) {
  const { applications, loading } = props;

  if (loading) {
    return <TableListSkeleton />;
  }

  const rows = applications.map((application, index) => (
    <ApplicationRow key={`application-${index}`} application={application} />
  ));

  return (
    <div className={commonStyles.table_content}>
      <ApplicationTableHeader />
      {rows}
    </div>
  );
}

function PPSRRegistrationIndex(props) {
  const {
    applicationParams,
    applications,
    meta,
    onFetchPPSRRegistrations,
    onFetchFilterOptions,
    branchOptions,
    updateApplicationParams,
    loading,
  } = useApplicationsState(props);

  const fetchPPSRRegistrations = useRef(
    debounce(onFetchPPSRRegistrations, DEBOUNCE_INTERVAL)
  ).current;

  const { currentUser, dispatch } = props;

  useEffect(() => {
    onFetchFilterOptions();
  }, []);

  useEffect(() => {
    fetchPPSRRegistrations(applicationParams);
  }, [applicationParams]);

  const onClickPage = (page) => updateApplicationParams({ page });

  const onClickPerPage = (per) => {
    currentUser.updatePreferences({ ppsrRegistrationPerPage: per });
    dispatch({
      payload: currentUser.attributes.preference,
      type: USER_UPDATE_PREFERENCE_SUCCESS,
    });
    updateApplicationParams({ page: 1, per });
  };

  return (
    <DashboardPageContainer
      pageTitle="PPSR financing statements"
      headerComponents={
        <Fragment>
          <StatisticsBlock
            meta={meta}
            activeBlock={applicationParams.active_tab}
            onClickBlock={onFetchPPSRRegistrations}
          />
          <Filter
            applicationParams={applicationParams}
            updateApplicationParams={updateApplicationParams}
            onFetchPPSRRegistrations={onFetchPPSRRegistrations}
            branchOptions={branchOptions}
            {...props}
          />
        </Fragment>
      }
      contentComponents={
        <Fragment>
          <ApplicationTable
            applications={applications}
            loading={loading}
            meta={meta}
            {...props}
          />
          <Pagination
            dataLength={meta.total}
            currentPage={applicationParams.page}
            todosPerPage={applicationParams.per}
            resourceName="Entries"
            optionViewPage={[
              { label: '25', value: DEFAULT_PER_PAGE },
              { label: '50', value: 50 },
            ]}
            handleClick={onClickPage}
            handleClickTodosPerPage={onClickPerPage}
          />
        </Fragment>
      }
    />
  );
}

export default connect((state) => {
  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
  };
})(PPSRRegistrationIndex);
