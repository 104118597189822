import api from 'api';
import { FEATURE_FLAGS } from 'conf';
import debounce from 'debounce';
import { isEqual } from 'lodash';
import UserModel from 'models/UserModel';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
// @ts-ignore-next-line: TS is not able to find `browserHistory` as an exported value of react-router but definitely there is
import { browserHistory } from 'react-router';

import Filter from './components/Filter';
import PipelineTable from './components/PipelineTable';
import StatisticsBlock from './components/StatisticsBlock';
import { createQuery, defaultSearchParams } from './helpers';
import { DashboardPageContainer } from 'modules/shared/components';
import { PipelineData } from './types';

const getDefaultBlock = (query) => {
  const { bucket_query } = query;
  if (bucket_query) {
    return bucket_query.replace('bucket_', '');
  }

  return null;
};

export const useWatchtowerPipelineState = (props) => {
  const { currentUser, location } = props;

  const [pipelineData, setPipelineData] = useState<PipelineData>();
  const [searchParams, internalUpdateSearchParams] = useState<any>({
    ...defaultSearchParams,
    ...location.query,
  });

  const [activeBlock, setActiveBlock] = useState<string>(
    getDefaultBlock(location.query) || 'total'
  );

  const [loading, setLoading] = useState<boolean>(false);

  const applicationAPI = api(
    'applications',
    currentUser.accessToken,
    currentUser.currentEntity.id
  );

  const removeFromGroup = (applicationId) => {
    applicationAPI.ungroupApplicationFromAlert(applicationId, () =>
      window.location.reload()
    );
  };

  const fetchAlertData = (queryParams) => {
    setLoading(true);
    applicationAPI
      .getWatchtowerPipeline({
        ...queryParams,
      })
      .then((response) => {
        if (response.data) {
          setPipelineData(response.data);
        }
        setLoading(false);
      });
  };

  const debouncedFetchAlertData = useRef(debounce(fetchAlertData, 500)).current;

  useEffect(() => {
    setLoading(true);
    const queryParams = createQuery(activeBlock, searchParams);

    if (!isEqual(queryParams, location.query)) {
      browserHistory.push({
        pathname: '/dashboard/watchtower',
        query: isEqual(queryParams, defaultSearchParams) ? {} : queryParams,
        state: location.state,
      });
    }

    debouncedFetchAlertData(queryParams);
  }, [activeBlock]);

  useEffect(() => {
    if (activeBlock) {
      const originalSearchParams = { ...searchParams };
      let queryParams = {
        ...createQuery(activeBlock, searchParams),
        ...originalSearchParams,
      };

      if (queryParams.key_word !== location.query.key_word) {
        queryParams = {
          ...queryParams,
          page: 1,
        };
      }

      if (!isEqual(queryParams, location.query)) {
        browserHistory.push({
          pathname: '/dashboard/watchtower',
          query: isEqual(queryParams, defaultSearchParams) ? {} : queryParams,
          state: location.state,
        });
      }

      debouncedFetchAlertData(queryParams);
    }
  }, [searchParams]);

  const updateSearchParams = (newParams, includePrev = true) => {
    internalUpdateSearchParams((prevParams) => {
      if (!newParams.key_word) {
        delete prevParams['key_word'];
      }

      return {
        ...(includePrev ? prevParams : {}),
        ...newParams,
      };
    });
  };

  return {
    activeBlock,
    fetchAlertData,
    pipelineData,
    setActiveBlock,
    searchParams,
    updateSearchParams,
    loading,
    removeFromGroup,
  };
};

const WatchtowerPipeline = (props) => {
  if (!FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER) {
    browserHistory.push({ pathname: '/dashboard/home' });

    return null;
  }
  const {
    pipelineData,
    updateSearchParams,
    setActiveBlock,
    activeBlock,
    searchParams,
    loading,
    removeFromGroup,
  } = useWatchtowerPipelineState(props);

  const onClickBlock = (params) => {
    const { active_block } = params;
    setActiveBlock(active_block);
  };

  return (
    <DashboardPageContainer
      pageTitle="Watchtower"
      headerComponents={
        <React.Fragment>
          <StatisticsBlock
            summary={pipelineData?.meta ? pipelineData.meta.summary : {}}
            activeBlock={activeBlock}
            onClickBlock={onClickBlock}
          />
          <Filter
            updateSearchParams={updateSearchParams}
            searchParams={searchParams}
            {...props}
          />
        </React.Fragment>
      }
      contentComponents={
        <PipelineTable
          key={activeBlock}
          activeBlock={activeBlock}
          {...props}
          loading={loading}
          meta={pipelineData?.meta ? pipelineData.meta : {}}
          tableData={pipelineData?.data ? pipelineData.data : []}
          updateSearchParams={updateSearchParams}
          removeFromGroup={removeFromGroup}
        />
      }
    />
  );
};

export default connect((state) => {
  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
  };
})(WatchtowerPipeline);
