import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { checkHasSectionData, getDefaults } from './utils';
import styles from './summary-report-styles.css';
import { SectionContentContainer } from '../styles';
import { IDefaults, TDefault } from './types';
import { formatDate } from 'utils/dateFormatter';
import formatMoney from 'utils/formatMoney';

const DefaultsContent = ({ defaults }: { defaults: IDefaults }) => {
  const { Defaults = [] } = defaults;
  return (
    <SectionContentContainer>
      <table className={styles.table}>
        <thead>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_1}>Date</th>
            <th className={styles.th + ' ' + styles.col_2}>Account Type</th>
            <th className={styles.th + ' ' + styles.col_3}>Credit Provider</th>
            <th className={styles.th + ' ' + styles.col_2}>Reference</th>
            <th className={styles.th + ' ' + styles.col_1}>Original Amount</th>
            <th className={styles.th + ' ' + styles.col_1}>Current Balance</th>
            <th className={styles.th + ' ' + styles.col_1}>Status</th>
            <th className={styles.th + ' ' + styles.col_1}>Status Date</th>
          </tr>
        </thead>
        <tbody>
          {!!Defaults &&
            Defaults.length > 0 &&
            Defaults.map((defaultData: TDefault) => (
              <tr className={styles.row}>
                <td className={styles.td + ' ' + styles.col_1}>
                  {formatDate(defaultData.DateOfDefault, 'DD/MM/YYYY') || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_2}>
                  {defaultData.AccountTypeDescription || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_3}>
                  {defaultData.CreditProvider || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_2}>
                  {defaultData.Reference || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_1}>
                  {`$${formatMoney(defaultData.OriginalDefaultAmount || 0)}` ||
                    '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_1}>
                  {`$${formatMoney(defaultData.CurrentDefaultBalance || 0)}` ||
                    '-'}{' '}
                </td>
                <td className={styles.td + ' ' + styles.col_1}>
                  {defaultData.DefaultStatusDescription || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_1}>
                  {formatDate(defaultData.StatusDate, 'DD/MM/YYYY') || '-'}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </SectionContentContainer>
  );
};

const Defaults = ({ isAllExpanded, data }) => {
  const defaults: IDefaults = getDefaults(data);
  const hasSectionData = checkHasSectionData(defaults);

  return (
    <ReviewContainer
      subHeading="Defaults"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <DefaultsContent defaults={defaults} />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default Defaults;
