import { Box, Drawer } from 'mui-latest';
import styled from 'styled-components';
import { FONT_SETS } from 'variables/typography';

export const ButtonDrawer = styled(Drawer)`
  .MuiDrawer-paper{
    box-shadow: 0 0 25px rgb(0 0 0 / 25%) !important;
    margin-top: 80px;
    overflow-x: hidden;
    width: 385px;
`;

export const CloseDrawerIcon = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0px 0px;
  width: 100%;
  position: sticky;
  top: 0;
  button {
    border: 2px solid var(--main-color);
    padding: 8px;
  }
  svg {
    font-size: 2.2rem;
  }
`;

export const ScrollableBox = styled(Box)`
  padding-bottom: 100px;
`;

export const Description = styled.div`
  ${FONT_SETS.description}

  @media (max-width: 767px) {
    margin-bottom: 10px;
    padding-left: 24px;
  }
`;

export const DrawerPanel = styled.div`
  padding: 20px 40px 0px 40px;
`;

export const Header = styled.div`
  ${FONT_SETS.sectionHeader}
  margin-bottom: 10px;

  @media (max-width: 767px) {
    margin: 10px 0px 10px 0px;
    padding-left: 24px;
  }
`;

export const TermsUrlWrapper = styled.div`
  ${FONT_SETS.description}
  margin-top: 30px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
    padding-left: 24px;
  }
`;
