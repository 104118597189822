import { getLabelForValue } from 'modules/consumer-onboarding/components/onboarding/utils/disablePersonalEntityType';
import {
  NZBN_LENGTH,
  onRetrieveDetailsWithBusinessNumber,
} from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/NewZealandBusinessOverview/utils/retrieveDetails';
import OverviewForm from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/OverviewForm';
import React, { ReactElement } from 'react';
import isBlank from 'utils/isBlank';
import * as yup from 'yup';

const validationSchema = ({ validEntityTypes }) =>
  yup.object().shape({
    businessNumber: yup
      .string()
      .when('legalType', {
        is: (legalType) => legalType === 'company',
        otherwise: (schema) => schema.nullable(),
        then: (schema) => schema.required('Please enter a valid NZBN'),
      })
      .test('nzbn-length', 'Please enter a valid NZBN', function (value) {
        if (isBlank(value)) {
          return true;
        }

        return value.length === NZBN_LENGTH;
      }),
    companyNumber: yup.string().when('legalType', {
      is: (legalType) => legalType === 'company',
      otherwise: (schema) => schema,
      then: (schema) =>
        schema.required(
          'Please enter NZBN to retrieve the correct company number'
        ),
    }),
    entityName: yup
      .string()
      .required('This field is mandatory')
      .test('no-only-spaces', 'This field is mandatory', (value) => {
        return value.trim().length > 0;
      }),
    legalType: yup.string().oneOf(validEntityTypes, (message) => {
      const { value } = message;

      if (isBlank(value)) {
        return 'This field is mandatory';
      }

      const label = getLabelForValue(message.value);
      return `The type of the selected entity is ${label} and is not a valid option.`;
    }),
  });

const NewZealandBusinessOverview = (props): ReactElement => (
  <OverviewForm
    businessNumberLabel="NZBN"
    companyNumberLabel="NZCN"
    companyNumberDisabled={true}
    onRetrieveDetailsWithBusinessNumber={(params) =>
      onRetrieveDetailsWithBusinessNumber(params)
    }
    validationSchema={validationSchema}
    {...props}
  />
);

export default NewZealandBusinessOverview;
