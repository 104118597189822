import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { checkHasSectionData, getJudgements } from './utils';
import styles from './summary-report-styles.css';
import { SectionContentContainer } from '../styles';
import { IJudgements, TJudgement } from './types';
import { formatDate } from 'utils/dateFormatter';
import formatMoney from 'utils/formatMoney';

const JudgementsContent = ({ judgements }: { judgements: IJudgements }) => {
  const { Judgements = [] } = judgements;
  return (
    <SectionContentContainer>
      <table className={styles.table}>
        <thead>
          <tr className={styles.row}>
            <th className={styles.th + ' ' + styles.col_1}>Judgement Date</th>
            <th className={styles.th + ' ' + styles.col_3}>Plaintiff</th>
            <th className={styles.th + ' ' + styles.col_3}>Amount</th>
            <th className={styles.th + ' ' + styles.col_3}>Status</th>
            <th className={styles.th + ' ' + styles.col_1}>Status Date</th>
            <th className={styles.th + ' ' + styles.col_1}>Status Source</th>
          </tr>
        </thead>
        <tbody>
          {!!Judgements &&
            Judgements.length > 0 &&
            Judgements.map((judgement: TJudgement) => (
              <tr className={styles.row}>
                <td className={styles.td + ' ' + styles.col_1}>
                  {formatDate(judgement.SealedDate, 'DD/MM/YYYY') || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_3}>
                  {judgement.Plaintiffs ? judgement.Plaintiffs.join(', ') : '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_3}>
                  {judgement.Amount ? `$${formatMoney(judgement.Amount)}` : '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_3}>
                  {judgement.Status || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_1}>
                  {formatDate(judgement.StatusDate, 'DD/MM/YYYY') || '-'}
                </td>
                <td className={styles.td + ' ' + styles.col_1}>
                  {judgement.StatusSource || '-'}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </SectionContentContainer>
  );
};

const Judgements = ({ isAllExpanded, data }) => {
  const judgements: IJudgements = getJudgements(data);
  const hasSectionData = checkHasSectionData(judgements);

  return (
    <ReviewContainer
      subHeading="Judgements"
      content_class="content_wide"
      collapsible={hasSectionData}
      isAllExpanded={hasSectionData ? isAllExpanded : undefined}
      fontSize="medium"
      css_class="block_medium_bottomborder"
    >
      {hasSectionData ? (
        <JudgementsContent judgements={judgements} />
      ) : (
        <p>Not present on file</p>
      )}
    </ReviewContainer>
  );
};

export default Judgements;
