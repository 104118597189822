import isBlank from 'utils/isBlank';

export const ENTITY_TYPE_OPTIONS = [
  { label: 'Company', value: 'company' },
  { label: 'Partnership', value: 'partnership' },
  { label: 'Trust', value: 'trust' },
  { label: 'Sole trader', value: 'sole_trader' },
  { label: 'Personal', value: 'personal' },
  { label: 'Education', value: 'education' },
  { label: 'Society', value: 'society' },
  { label: 'Association', value: 'association' },
  { label: 'Club', value: 'club' },
  { label: 'Government', value: 'government' },
  { label: 'Other', value: 'other' },
];

export const getDisabledEntityError = (params) => {
  const { legalType, supplierLegalName } = params || {};
  switch (legalType) {
    case 'trust':
      return `Trust applications are not accepted. Please contact ${supplierLegalName}.`;

    default:
      return undefined;
  }
};

export const getDisabledEntities = (customConfiguration) => {
  if (isBlank(customConfiguration)) {
    return [];
  }

  return customConfiguration
    .filter((config) => config.disabled)
    .map((config) => config.entity_type);
};

export const availableEntityTypes = (customConfiguration) => {
  if (isBlank(customConfiguration)) {
    return ENTITY_TYPE_OPTIONS;
  }

  const disabledEntities = getDisabledEntities(customConfiguration);

  return ENTITY_TYPE_OPTIONS.filter(
    (option) => !disabledEntities.includes(option.value)
  );
};

export const getLabelForValue = (value) => {
  const labelValuePair = ENTITY_TYPE_OPTIONS.find(
    (option) => option.value === value
  );

  if (labelValuePair) {
    return labelValuePair.label;
  }

  throw `${value} is an invalid option`;
};

export const getCustomConfigurationByEntityType = (
  customConfiguration,
  entityType
) => customConfiguration.find((config) => config.entity_type === entityType);
