import React from 'react';
import { CardContent, Collapse } from 'mui-latest';
import StatusIndicator from 'modules/shared/components/widgets/static/StatusIndicator';
import {
  WatchtowerAlertBucketRoot,
  WatchtowerAlertHeader,
  WatchtowerAlertHeaderRoot,
  WatchtowerAlertInfo,
  WatchtowerActionAll,
  WatchtowerActionAllDisabled,
} from '../styles';
import WatchtowerAlertTable from './WatchtowerAlertsTable';
import { ALERT_TOOLTIP_MAP } from '../constants';
import { formatDate } from 'utils/dateFormatter';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import api from 'api';
import { FEATURE_FLAGS } from 'conf';

export const WatchtowerAlertAction = ({
  label,
  currentUser,
  applicationId,
  alertName = undefined,
  onFetchApplicationRecord,
  actionAllAlert = false,
  disabled = false,
}) => {
  const applicationAPI = api(
    'applications',
    currentUser.accessToken,
    currentUser.currentEntity.id
  );

  const handleActionAlert = (event) => {
    if (disabled) return;

    applicationAPI.watchtowerAlertMarkAsSafe(applicationId, alertName, () =>
      onFetchApplicationRecord()
    );
  };

  const tooltipText = actionAllAlert
    ? 'Clicking here will mark all alerts on this VCF and its grouped accounts (if applicable) as reviewed.'
    : 'Clicking here will mark all alerts in this category and its grouped accounts (if applicable) as reviewed.';

  return (
    <div>
      {disabled ? (
        <WatchtowerActionAllDisabled>{label}</WatchtowerActionAllDisabled>
      ) : (
        <PopperTooltip title={tooltipText}>
          <WatchtowerActionAll
            aria-controls="fade-menu"
            aria-haspopup="true"
            onClick={handleActionAlert}
          >
            {label}
          </WatchtowerActionAll>
        </PopperTooltip>
      )}
    </div>
  );
};

const formatAlertData = (alertData: any = {}) => {
  if (!FEATURE_FLAGS.FEATURE_FLAGS_UPDATED_WT_VCF) {
    return alertData;
  }

  return {
    latestAlertDate: alertData.alert_date,
    alertNameLabel: alertData.alert_label,
    alertName: alertData.alert_name,
    alertType: alertData.alert_type,
    linkedApplications: alertData.data,
    isActioned: alertData.alert_status !== 'open',
  };
};

const WatchtowerAlertBucket = ({
  alertData,
  entityTierData,
  currentUser,
  applicationId,
  onFetchApplicationRecord,
  isFlaggedAccount,
  isDisabledVcf,
}) => {
  const {
    latestAlertDate,
    alertNameLabel,
    alertName,
    alertType,
    linkedApplications = [],
    isActioned,
  } = formatAlertData(alertData) || {};

  const alertDate = formatDate(latestAlertDate, 'DD/MM/YYYY');
  const alertDateDisplay =
    alertDate === 'Invalid date' ? latestAlertDate : alertDate;
  const hasLinkedApplications =
    linkedApplications && linkedApplications.length > 0;

  const handleExpandClick = () => {
    if (!hasLinkedApplications) return;

    setExpanded(!expanded);
  };

  const [expanded, setExpanded] = React.useState(hasLinkedApplications);

  return (
    <WatchtowerAlertBucketRoot raised={!expanded}>
      <WatchtowerAlertHeaderRoot
        alert_type={isFlaggedAccount || !isActioned ? alertType : undefined}
        raised
        onClick={handleExpandClick}
        disabled={!hasLinkedApplications}
      >
        <WatchtowerAlertHeader>
          <StatusIndicator status={alertType} />
          <WatchtowerAlertInfo>
            {ALERT_TOOLTIP_MAP[alertName] ? (
              <PopperTooltip title={ALERT_TOOLTIP_MAP[alertName]}>
                <span>{alertNameLabel}</span>
              </PopperTooltip>
            ) : (
              <span>{alertNameLabel}</span>
            )}
            <span>{alertDateDisplay}</span>
          </WatchtowerAlertInfo>
          <WatchtowerAlertAction
            label="Mark as actioned"
            currentUser={currentUser}
            applicationId={applicationId}
            alertName={alertName}
            onFetchApplicationRecord={onFetchApplicationRecord}
            disabled={isDisabledVcf}
          />
        </WatchtowerAlertHeader>
      </WatchtowerAlertHeaderRoot>
      {hasLinkedApplications && (
        <Collapse in={expanded}>
          <CardContent>
            <WatchtowerAlertTable
              linkedApplications={linkedApplications}
              entityTierData={entityTierData}
              alertName={alertName}
              currentEntityId={
                currentUser.currentEntity ? currentUser.currentEntity.id : null
              }
            />
          </CardContent>
        </Collapse>
      )}
    </WatchtowerAlertBucketRoot>
  );
};

export default WatchtowerAlertBucket;
