import { Checkbox, FormControlLabel } from '@material-ui/core';
import { FEATURE_FLAGS } from 'conf';
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import RecordHistory from 'modules/new-applications/components/RecordHistory';
import styles from 'modules/new-applications/css/LoadedInSystem.css';
import ContentWithFooter from 'modules/shared/components/containers/ContentWithFooter';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import React, { useState } from 'react';

export default function LoadedInSystem(props) {
  const { application, currentUser, onFetchApplicationRecord } = props;
  const {
    loadedInSystem,
    canBeLoadedInSystem,
    loadedInSystemHistories,
    isShadowApplication,
  } = application;
  const [isLoadedInSystem, setIsLoadedInSystem] = useState(
    loadedInSystem || false
  );

  const onSuccessCallback = (updatedApplication) => {
    mixpanel.track('Loaded in system', {
      Application: application.id,
      'Entity ID': get(currentUser, 'currentEntity.id'),
      distinct_id: currentUser.id,
      loaded_in_system: updatedApplication.loadedInSystem || false,
    });

    onFetchApplicationRecord();
  };

  const onChange = (event) => {
    const isChecked = get(event, 'target.checked', false);

    setIsLoadedInSystem(isChecked);

    application.update({
      attributes: { loaded_in_system: isChecked },
      currentUser,
      onSuccessCallback,
    });
  };

  const isDisabled = FEATURE_FLAGS.FEATURE_FLAG_LOADED_IN_SYSTEM_CHECKS
    ? isLoadedInSystem || !canBeLoadedInSystem || isShadowApplication
    : isLoadedInSystem || isShadowApplication;

  const withTooltip =
    FEATURE_FLAGS.FEATURE_FLAG_LOADED_IN_SYSTEM_CHECKS &&
    !application.canBeLoadedInSystem;

  const checkbox = (
    <FormControlLabel
      classes={{
        label: styles.checkbox_label,
      }}
      control={
        <Checkbox
          className={styles.checkbox}
          checked={isLoadedInSystem}
          disabled={isDisabled}
          onChange={onChange}
        />
      }
      label="Loaded in system?"
    />
  );

  const content = (
    <FixedContent header="Loaded in system">
      <GridContent>
        {withTooltip ? (
          <PopperTooltip title="Applications cannot be loaded in system where unactioned Watchtower alert applies, or account marked as Fraudulent/Undesirable.">
            {checkbox}
          </PopperTooltip>
        ) : (
          checkbox
        )}
      </GridContent>
    </FixedContent>
  );

  if (loadedInSystemHistories.length > 0) {
    return (
      <ContentWithFooter
        footer={<RecordHistory histories={loadedInSystemHistories} />}
      >
        {content}
      </ContentWithFooter>
    );
  }

  return content;
}
