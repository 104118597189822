import React, { ReactElement, useState } from 'react';
import Button from 'modules/shared/components/inputs/Button';
import Form from 'modules/new-applications/components/application-sections/LimitAndApprovals/RevertDecision/Form';
import UserModel from 'models/UserModel';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import { ButtonWrapper } from 'modules/new-applications/components/application-sections/LimitAndApprovals/RevertDecision/style';
import isBlank from 'utils/isBlank';

const RevertDecision = ({
  application,
  currentUser,
  onRevertDecisionCallback,
  onSetAlert,
  review,
  escalationId,
}: {
  application: any;
  currentUser: UserModel;
  onRevertDecisionCallback: () => void;
  onSetAlert: () => void;
  review: any;
  escalationId?: string;
}): ReactElement | null => {
  const { canRevertDecision, isLoadedInSystem, isShadowApplication } =
    application;

  if (isBlank(currentUser) || currentUser.isStandard || !canRevertDecision) {
    return null;
  }

  const [isRevertFormVisible, setIsRevertFormVisible] = useState(false);

  if (isRevertFormVisible) {
    return (
      <Form
        //review={review}
        application={application}
        currentUser={currentUser}
        onCloseForm={() => setIsRevertFormVisible(false)}
        onRevertDecisionCallback={onRevertDecisionCallback}
        onSetAlert={onSetAlert}
        //escalationId={escalationId}
      />
    );
  }

  const isDisabled = isLoadedInSystem || isShadowApplication;

  return (
    <FixedContent withBottomMargin={false}>
      <ButtonWrapper>
        <Button
          disabled={isDisabled}
          text="Revert decision"
          handleClick={
            isDisabled ? () => {} : () => setIsRevertFormVisible(true)
          }
        />
      </ButtonWrapper>
    </FixedContent>
  );
};

export default RevertDecision;
