import * as React from 'react';

import OverlayChecklist from '../../OverlayChecklist';
import { DrawerPanel, Header } from '../styles';
import Button from 'modules/shared/components/inputs/Button';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  margin-top: 28px;
`;

export const ChecklistPanel = (props) => {
  const { applicationChecklist, applicationId, onButtonClick } = props;

  return (
    <DrawerPanel>
      <Header>Checklist</Header>
      <OverlayChecklist
        {...applicationChecklist}
        applicationId={applicationId}
      />
      <StyledButton onClick={onButtonClick} text="Got it!" type="button" />
    </DrawerPanel>
  );
};
