import { isDate } from 'lodash';
import get from 'lodash.get';
import AddonRuleModel from 'models/AddonRuleModel';
import ApplicationModel from 'models/ApplicationModel';
import LeadModel from 'models/LeadModel';
import UserModel from 'models/UserModel';
import ContentWithFooter from 'modules/shared/components/containers/ContentWithFooter';
import ScrollableContent from 'modules/shared/components/containers/ScrollableContent';
import React, { ReactElement, ReactNode, useState } from 'react';
import isPresent from 'utils/isPresent';
import sortByCreatedAt from 'utils/sortByCreatedAt';

import RecordHistory from '../../RecordHistory';
import useIUFApproverState from './hooks/useIUFApproverState';
import useIUFState from './hooks/useIUFState';
import IUFEdit from './IUFEdit';
import useProcessedAddonAnswers from './IUFEdit/useProcessedAddonAnswers';
import IUFView from './IUFView';
import { FEATURE_FLAGS } from 'conf';

export interface IIUFProps {
  lead: LeadModel;
  application: ApplicationModel;
  currentUser: UserModel;
  onFetchApplicationRecord: () => void;
}

type WrapperProps = {
  children: ReactNode;
  owner: ApplicationModel | LeadModel;
  iufAddonRule: AddonRuleModel;
  isIUFApproved: boolean;
};

function Wrapper({
  children,
  owner,
  iufAddonRule,
  isIUFApproved,
}: WrapperProps): ReactElement {
  const addonAnswerHistories = get(
    owner,
    'iufAddonAnswers.addonAnswerHistories',
    []
  );
  const iufApprovalHistories = get(
    owner,
    'iufApproval.iufApprovalHistories',
    []
  );
  const histories = sortByCreatedAt([
    ...addonAnswerHistories,
    ...iufApprovalHistories,
  ]);
  const iufVersion = get(iufAddonRule, 'formattedRuleLabel');

  return (
    <ContentWithFooter
      footer={
        <>
          {histories.length > 0 && <RecordHistory histories={histories} />}
          {!isIUFApproved && iufVersion}
        </>
      }
      withBodyBottomPadding
    >
      {children}
    </ContentWithFooter>
  );
}

function IUF(props: IIUFProps): ReactElement | null {
  const { application, lead } = props;
  const iufAddonRule = (application || lead).iufAddonRule;
  const [edit, setEdit] = useState(false);
  const owner = application || lead;
  const { noAnswer, isCompany } = useIUFState(owner);
  const iufApproverState = useIUFApproverState({ ...props, owner });
  const { isIUFApproved } = iufApproverState;
  const { isDraft } = useProcessedAddonAnswers(owner);
  const [isSubmitted, setIsSubmitted] = useState(!isDraft);

  function editOn() {
    setEdit(true);
  }

  function editOff() {
    setEdit(false);
  }

  function showEdit() {
    return edit || noAnswer || (isDraft && !isSubmitted);
  }

  return (
    <ScrollableContent>
      <Wrapper
        {...props}
        isIUFApproved={isIUFApproved}
        iufAddonRule={iufAddonRule}
        owner={owner}
      >
        {showEdit() ? (
          <IUFEdit
            handleComplete={editOff}
            isCompany={isCompany}
            owner={owner}
            setSubmitted={setIsSubmitted}
            isDisabled={application ? application.isDisabledVcf : false}
            {...props}
          />
        ) : (
          <IUFView {...props} owner={owner} editOn={editOn} />
        )}
      </Wrapper>
    </ScrollableContent>
  );
}

export default IUF;
