import styled from 'styled-components';
import { COLORS } from 'variables/theme';
import { FONT_SIZES } from 'variables/typography';

export const Wrapper = styled.div`
  grid-area: slider;
  background: ${COLORS.white};
  max-width: 385px;
  padding: 20px 40px;
  padding-top: 40px;
  z-index: 99;

  h2 {
    font-size: ${FONT_SIZES.heading};
    margin-bottom: 1rem;
  }

  p {
    overflow-wrap: anywhere;
  }

  a {
    overflow-wrap: break-word;
  }

  > div > div:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const CheckboxWrapper = styled.div`
  & > div {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const TitleWrapper = styled.h2`
  font-size: 1.167rem !important;
`;

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    'head'
    'slider';
  grid-template-rows: 80px 1fr;
  grid-template-columns: 385px;
`;
export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
`;

export const Header = styled.div`
  grid-area: head;
  background-color: var(--main-color);
  height: 80px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;

  button {
    margin-right: 0 !important;
  }
`;

export const ChecklistPreview = styled.div`
  position: relative;
  width: 385px;
`;
export const CloseWrapper = styled.span`
  position: absolute;
  top: 90px;
  right: 10px;
  button {
    border: 2px solid var(--main-color);
    padding: 8px;
  }
  svg {
    font-size: 2.2rem;
  }
`;

export const CheckboxContainer = styled.div`
  & > div {
    @media (max-width: 767px) {
      float: initial !important;
    }
  }
`;
